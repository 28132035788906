import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { touch, untouch } from 'redux-form';
import { Progress } from 'reactstrap';
import moment from 'moment';
import { CSV_MIN_FILE_SIZE, MIN_FILE_SIZE, MAX_FILE_SIZE, MAX_CV_FILE_SIZE, VALIDATION } from 'utils/constants';
import messages from './messages';
import { API_URL, CV, LINKEDIN_PROFILE, TALENT, VERSION2 } from 'containers/App/constants';
import request from 'utils/request';
import { toast } from 'react-toastify';
import { ToastifyMessage } from 'components';

export const getSelectedFieldFromList = (list, listCompareKey, value) => list.find((c) => c[listCompareKey] === value) || '';

export const getSelectedLanguage = (list, value) => {
  const selectedLanguages = [];
  (value || []).forEach((l) => {
    const foundLanguage = list.find((language) => language.code === l.name);
    if (foundLanguage) {
      selectedLanguages.push({
        value: foundLanguage.code,
        label: foundLanguage.language,
      });
    } else {
      selectedLanguages.push({
        value: l.name,
        label: l.name,
      });
    }
  });
  return selectedLanguages;
};

export const getSelectedCountryCode = (list, value) =>
  list.find(
    (c) =>
      // eslint-disable-next-line prettier/prettier
      c.phoneCode.replace('+', '') === value,
  ) || '';

export const getLanguageRatings = (language, value) =>
  language.map((l) => {
    const found = (value || []).find((responseLanguage) => responseLanguage.name === l.value) || { rate: '' };
    return {
      value: l.value,
      label: l.label,
      rating: found.rate,
    };
  });

export const getDate = (date) => (date ? moment(date) : '');

export const getFormattedObject = (data, fieldKey, valueKey, labelKey) =>
  data[fieldKey][valueKey] && data[fieldKey][labelKey] ? { label: data[fieldKey][labelKey], value: data[fieldKey][valueKey] } : '';

export const setInitialReduxState = ({
  response,
  change,
  countryCode,
  country,
  key,
  timeZone,
  dispatch,
  gender,
  primaryRole,
  yearsOfExperience,
}) => {
  Object.keys(response.data).forEach((fieldKey) => {
    let resData;
    switch (fieldKey) {
      case 'countryCode':
      case 'country':
      case 'timeZone':
      case 'gender':
      case 'primaryRole':
      case 'yearsOfExperience':
        resData = getFormattedObject(
          { countryCode, country, timeZone, gender, primaryRole, yearsOfExperience },
          fieldKey,
          'name',
          fieldKey === 'countryCode' ? 'phoneCode' : 'name',
        );
        break;
      case 'dob':
        resData = getDate(response.data.dob);
        break;
      default:
        resData = response.data[fieldKey];
        break;
    }
    dispatch(change(key, fieldKey, resData));
  });
  if (!get(response, 'data.countryCode', '')) {
    const codeData = getFormattedObject({ countryCode }, 'countryCode', 'name', 'phoneCode');
    dispatch(change(key, 'countryCode', codeData));
  }
};

export const getBtnClass = (loading, responseSuccess, responseError) => {
  let output = 'btn-primary ';
  if (loading) {
    output = 'btn-primary loading';
  }
  if (responseSuccess) {
    output = 'btn-primary request-success';
  }
  if (responseError) {
    output = 'btn-primary request-error';
  }
  return output;
};

export const fileMinSize = (type) => {
  let output = '';
  if (type === 'csvUploader' || type === 'certificateUploader') {
    output = CSV_MIN_FILE_SIZE;
  } else if (
    type === 'logoUploader' ||
    type === 'profilePhotoUploader' ||
    type === 'multiplePhotosUploader' ||
    type === 'competitionBannerUploader'
  ) {
    output = MIN_FILE_SIZE;
  } else if (type === 'cvUploader') {
    output = CSV_MIN_FILE_SIZE;
  } else if (type === 'photoAndFileUploader') {
    output = CSV_MIN_FILE_SIZE;
  }
  return output;
};

export const fileMaxSize = (type) => {
  let output = '';
  if (type === 'csvUploader' || type === 'certificateUploader') {
    output = MAX_FILE_SIZE;
  } else if (
    type === 'logoUploader' ||
    type === 'profilePhotoUploader' ||
    type === 'multiplePhotosUploader' ||
    type === 'competitionBannerUploader'
  ) {
    output = MAX_FILE_SIZE;
  } else if (type === 'cvUploader') {
    output = MAX_FILE_SIZE;
  } else if (type === 'photoAndFileUploader') {
    output = MAX_CV_FILE_SIZE;
  }
  return output;
};

export const acceptedFileTypes = (type) => {
  let output = '';
  if (type === 'csvUploader') {
    output = '.csv,.xls,.xlsx';
  } else if (type === 'certificateUploader') {
    output = '.png,.jpg,.jpeg,.pdf';
  } else if (
    type === 'logoUploader' ||
    type === 'profilePhotoUploader' ||
    type === 'multiplePhotosUploader' ||
    type === 'competitionBannerUploader'
  ) {
    output = '.jpeg,.jpg,.png';
  } else if (type === 'cvUploader') {
    output = '.doc,.docx,.pdf';
  } else if (type === 'photoAndFileUploader') {
    output = '.jpeg,.jpg,.png,.pdf';
  }
  return output;
};

export const dropAreaMessage = (type) => {
  let output = '';
  if (type === 'csvUploader') {
    output = <FormattedMessage {...messages.csvFileSizeInfo} />;
  } else if (type === 'logoUploader' || type === 'profilePhotoUploader' || type === 'multiplePhotosUploader') {
    output = <FormattedMessage {...messages.fileSizeInfo} />;
  } else if (type === 'cvUploader') {
    output = <FormattedMessage {...messages.cvFileSizeInfo} />;
  } else if (type === 'photoAndFileUploader') {
    output = <FormattedMessage {...messages.photoAndPdfSizeInfo} />;
  } else if (type === 'certificateUploader') {
    output = <FormattedMessage {...messages.certificateSizeInfo} />;
  } else if (type === 'competitionBannerUploader') {
    output = <FormattedMessage {...messages.bannerSizeInfo} />;
  }
  return output;
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Byte';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

export const getFileExtension = (fileName) => {
  const temp = Math.max(0, fileName.lastIndexOf('.')) || Infinity;
  return fileName.slice(temp + 0);
};

export const getFileName = (fileName) => {
  const name = fileName.split('.').slice(0, -1).join('.');
  return name || fileName;
};

export const setTouchPersonalDetails = (dispatch, key, response) => {
  const { data } = response;
  const OptionFields = [
    'addressLineTwo',
    'state',
    'linkedInUrl',
    'gitHubUrl',
    'stackOverFlowUrl',
    'dribbbleUrl',
    'behanceUrl',
    'portfolioUrl',
  ];
  const fieldData = {
    firstName: get(data, 'firstName'),
    lastName: get(data, 'lastName'),
    countryCode: get(data, 'countryCode'),
    phoneNumber: get(data, 'phoneNumber'),
    dob: get(data, 'dob'),
    gender: get(data, 'gender'),
    addressLineOne: get(data, 'addressLineOne'),
    addressLineTwo: get(data, 'addressLineTwo'),
    city: get(data, 'city'),
    country: get(data, 'country'),
    state: get(data, 'state'),
    postcode: get(data, 'postcode'),
    timeZone: get(data, 'timeZone'),
    languageCount: get(data, 'language', []).length,
    languageRating: get(data, 'language', []).length,
    linkedInUrl: get(data, 'linkedInUrl'),
    gitHubUrl: get(data, 'gitHubUrl'),
    stackOverFlowUrl: get(data, 'stackOverFlowUrl'),
    dribbbleUrl: get(data, 'dribbbleUrl'),
    behanceUrl: get(data, 'behanceUrl'),
    portfolioUrl: get(data, 'portfolioUrl'),
  };

  Object.keys(fieldData).forEach((fieldKey) => {
    if (OptionFields.includes(fieldKey)) {
      dispatch(untouch(key, fieldKey));
    } else {
      dispatch(touch(key, fieldKey));
    }
  });
};

export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const fetchLinkedInProfileDetails = ({ url, loadUserDetails }) => {
  let progress = 0;
  const renderProgressBar = ({ color = 'warning', realProgress, progressText = 'Fetching details from LinkedIn...' }) => (
    <div>
      <Progress color={color} value={realProgress}>
        {realProgress.toFixed(2)}%
      </Progress>
      <div className="mt-2">{progressText}</div>
    </div>
  );
  const toastId = toast(renderProgressBar({ realProgress: progress }), {
    autoClose: false,
    closeOnClick: false,
    isLoading: true,
  });
  const interval = setInterval(() => {
    if (progress < 95) {
      progress += Math.random() * 5 + 1; // Random increments
      let color = 'warning';
      if (progress > 40 && progress < 80) {
        color = 'primary';
      } else if (progress >= 80) {
        color = 'success';
      }
      toast.update(toastId, {
        render: renderProgressBar({ color, realProgress: progress }),
      });
    }
  }, 800);
  const apiCallData = {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: {
      url,
    },
  };
  const requestURL = `${API_URL}${TALENT}${LINKEDIN_PROFILE}`;
  request(requestURL, apiCallData)
    .then((res) => {
      clearInterval(interval);
      if (res.status === 1) {
        loadUserDetails(res);
        toast.update(toastId, {
          render: renderProgressBar({ color: 'success', realProgress: 100, progressText: 'Data fetched from linkedIn successfully!' }),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        if (toastId) {
          toast.dismiss(toastId);
        }
        toast.error(<ToastifyMessage message={res.message || VALIDATION.wentWrong} type="error" />, {
          className: 'Toast-error',
        });
      }
    })
    .catch(() => {
      if (toastId) {
        toast.dismiss(toastId);
      }
      toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, {
        className: 'Toast-error',
      });
    });
};
