/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import { userExists, getUserRegisterType, formatURL } from 'utils/Helper';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownToggle } from 'reactstrap';
import { defaultProps, propTypes } from 'containers/proptypes';
import LogoutLink from 'components/LogoutLink';
import { A, Button, P } from 'components';
import SVG from 'react-inlinesvg';
import StorageService from 'utils/StorageService';
import Emitter from 'utils/emitter';
import history from 'utils/history';
import request from 'utils/request';
import {
  userIcon,
  settingIcon,
  logoutIcon,
  backArrowIcon,
  CLIENT,
  API_URL,
  USER,
  ADMIN,
  searchIcon,
  plusIcon,
  clientsIcon,
  postJobsIcon,
  plusSquareIcon,
  winnerCupNew,
  clientHelpLink,
  newHelpIcon,
} from 'containers/App/constants';
import containerMessage from 'containers/messages';
import { redirectTo, checkClientSignupIsCompleted } from 'containers/App/utils';
import addBriefMessages from 'containers/Client/AddBrief/messages';
import applyBriefMessages from 'containers/Talent/ApplyBrief/messages';
import { handleBackToAdmin } from 'containers/Admin/ProxyLogin/utils';
import ProfileCompleteModel from 'containers/ProfileCompleteModel';
import { getSkills } from 'containers/Auth/utils';
import Notifications from 'containers/Notifications';
import Autocomplete from 'containers/TalentListingPage/AutoComplete';
import ToastifyMessage from 'components/ToastifyMessage';
import { isEmpty, uniqBy, isNil } from 'lodash';
import { UserImg, UserProfileToggle, UserProfileMenu, HeaderActions, SecondaryNav, StartHiringMenu } from './header-style';
import messages from './messages';
import { primaryDarkNew } from '../../themes/variables';
import SVGIcon, { SearchSvg } from '../SVGIcon';
import { clientOnboardPage } from './constants';
import { setClientDetails } from 'containers/App/reducer';
import { connect } from 'react-redux';

const getUsrDetail = () => ({
  isSignupStep: StorageService.get('signupStep') ?? '',
});

const ProfileCompleteModelRef = React.createRef();

export class ClientHeader extends React.Component {
  constructor(props) {
    const path = history.location.pathname.split('/')[2];
    super(props);
    const profilePicture = localStorage.getItem('profilePicture');
    this.state = {
      profilePicture,
      skillsList: [],
      isSearchbarOpen: false,
      clientDetails: {},
      path,
    };
  }

  searchSkill = () => {
    const { isSearchbarOpen } = this.state;
    this.setState({ isSearchbarOpen: !isSearchbarOpen }, () => {
      Emitter.emit(Emitter.EVENTS.SEARCHBAR_OPEN, isSearchbarOpen);
    });
  };

  setConstructor = () => {
    const userType = StorageService.get('userType');
    const proxyType = StorageService.get('proxyType');
    const profilePicture = localStorage.getItem('profilePicture');
    const stateData = {
      dropdownOpen: false,
      isSignIn: userExists(),
      ...getUsrDetail(),
      userType,
      proxyType,
      badgeConfiguration: {},
      profilePicture,
      startHiringOpen: false,
    };
    this.setState({ ...stateData });
  };

  componentDidMount() {
    this.setConstructor();
    !['dashboard', ...clientOnboardPage].includes(this.state.path) && getSkills(this.setSkills); // avoids fetching skills on client dashboard

    Emitter.on('proxyLoginClient', (proxyLoginClient) => {
      if (proxyLoginClient) {
        this.setConstructor();
      }
    });
    Emitter.on('profilePicture', (profilePicture) => {
      let url = '';
      if (profilePicture) {
        url = `${profilePicture}?_t=${new Date().getTime()}`;
      }
      this.setState({
        profilePicture: url,
      });
      localStorage.setItem('profilePicture', url, { hash: true });
    });

    Emitter.on('proxyBackToAdmin', (proxyBackToAdmin) => {
      if (proxyBackToAdmin) {
        this.setState({ dropdownOpen: false }, () => {
          this.setConstructor();
        });
      }
    });
    Emitter.on('badgeConfigurationUpdated', (badgeConfigurationUpdated) => {
      this.setState({ badgeConfiguration: badgeConfigurationUpdated });
    });

    if ([2, 4, '2', '4'].includes(StorageService.get('userType'))) {
      this.callFetchAPI();
    }
  }

  callFetchAPI = () => {
    const data = {
      method: 'GET',
    };
    const requestURL = `${API_URL}${USER}/client-header-details`;
    request(requestURL, data)
      .then((response) => {
        this.props.dispatch(setClientDetails(response?.data));
        this.setState({ clientDetails: response?.data });
        StorageService.set('clientBrand', get(response, 'data.brand', ''), { hash: true });
        if (StorageService.get('userType') === '4') {
          const isSuperAdmin = get(response.data, 'accountUserId', null);
          StorageService.set('isSuperAdmin', isSuperAdmin, { hash: true });
        } else {
          const restrictedActions = get(response.data, 'restrictedActions', []);
          if (Array.isArray(restrictedActions)) {
            StorageService.set('restrictedActions', JSON.stringify(restrictedActions));
          }
          StorageService.set('UserSessionPresent', get(response, 'data.isUserSessionPresent'), { hash: true });
          localStorage.setItem('clientUserRole', JSON.stringify(get(response, 'data.userRole', {})), { hash: true });
          StorageService.set('subscribed', get(response, 'data.subscribed', 0), { hash: true });
          StorageService.set('validForm', JSON.stringify(get(response, 'data.validForm')), { hash: true });
          StorageService.set('subscriptionPlan', get(response.data, 'subscriptionPlan', 'Free'), { hash: true });
          StorageService.set('subscriptionPlanDetails', JSON.stringify(get(response, 'data.subscriptionPlanDetails')), { hash: true });
          StorageService.set('isClaimer', get(response, 'data.isClaimer'), { hash: true });
          StorageService.set('clientCompanyName ', get(response, 'data.name', null), { hash: true });
        }
      })
      .catch(() => {
        toast.error(<ToastifyMessage message="something went wrong" type="error" />, { className: 'Toast-error' });
      });
    this.setConstructor();
  };

  setSkills = (response) => {
    if (get(response, 'status')) {
      this.setState({ skillsList: response.data });
    } else {
      toast.error(<ToastifyMessage message={get(response, 'message')} type="error" />, { className: 'Toast-error' });
    }
  };

  componentWillUnmount() {
    Emitter.off('proxyLoginClient');
    Emitter.off('proxyBackToAdmin');
    Emitter.off('profilePicture');
    Emitter.off('badgeConfigurationUpdated');
  }

  /**
   * call toggle on tab change
   * @author Innovify
   */
  dropdownToggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  searchSkillsValueChangedTemp = (data, clearFilter) => {
    const { value } = data;
    const filterObjectGet = localStorage.getItem('filterObject');
    let filterObject;
    try {
      filterObject = JSON.parse(filterObjectGet) || { skillsArray: [] };
    } catch {
      filterObject = { skillsArray: [] };
    }
    const skillsArray = filterObject.skillsArray || [];
    const newSkillsArray = uniqBy(clearFilter ? skillsArray.filter((i) => i.value !== value) : [value].concat(skillsArray), 'value');
    this.storeSelectedFilterValue(
      'skillsArray',
      newSkillsArray.map((val) => val),
    );
    if (!(history && history.location && history.location.pathname === `${CLIENT}/listings`)) {
      redirectTo(history, `${CLIENT}/listings`);
    }
  };

  storeSelectedFilterValue = (keyName, KeyValue) => {
    const filterObjectGet = localStorage.getItem('filterObject');
    const filterObject = JSON.parse(filterObjectGet) || {};
    filterObject[keyName] = KeyValue;
    localStorage.setItem('filterObject', JSON.stringify(filterObject));
    Emitter.emit('filterObject');
  };

  render() {
    const { profilePicture, userType, isSignIn, dropdownOpen, proxyType, badgeConfiguration, skillsList, startHiringOpen, clientDetails } =
      this.state;
    const validFormData = StorageService.get('validForm');
    let validFormObj = {};
    if (validFormData !== 'undefined' && typeof validFormData !== 'undefined') {
      validFormObj = !isNil(validFormData) && JSON.parse(validFormData);
    }
    const isProfileCompleted = !isEmpty(validFormObj) && Object.values(validFormObj).some((item) => !item.status);
    const currentUserType = getUserRegisterType();
    const isSignupCompleted = checkClientSignupIsCompleted();
    const brand = StorageService.get('clientBrand');

    const handleJobRedirection = () => {
      if (currentUserType === 'admin' || (currentUserType === 'client' && isSignupCompleted && !isProfileCompleted)) {
        if (!clientDetails?.dashboardMetrics?.projects) {
          return history.push(`${CLIENT}/create-project`);
        }
        if (history.location.pathname === `${CLIENT}/create-brief`) {
          history.replace();
          window.location.reload();
        } else {
          history.push(`${CLIENT}/create-brief`);
        }
      } else {
        ProfileCompleteModelRef.current.handleProfileCompleteOpenModal();
      }
    };

    return (
      <>
        {isSignIn && userType === '2' && (
          <>
            <div className="d-flex header">
              {!clientOnboardPage.includes(this.state.path) && (
                <>
                  <div
                    className={`align-items-center search-bar ${
                      ['', 'dashboard', 'listings', 'talent-listing'].includes(this.state.path) ? 'd-none' : ''
                    }`}
                  >
                    <div className="d-none d-md-block">
                      <Autocomplete
                        customClass="client-header order-lg-0 order-1"
                        options={skillsList}
                        label=""
                        filterType="skillSearch"
                        placeholder="Enter skills to search for a talent or a team"
                        valueChanged={this.searchSkillsValueChangedTemp}
                      />
                    </div>
                    <div className="d-block d-md-none d-none me-3" onClick={this.searchSkill}>
                      <SearchSvg width="22px" height="22px" strokeWidth="1" src={searchIcon} iconColor={`rgba(${primaryDarkNew}, 0.3)`} />
                    </div>
                  </div>
                  <HeaderActions className="d-none d-sm-flex align-items-center">
                    <Dropdown
                      isOpen={startHiringOpen}
                      toggle={() => {
                        this.setState((prevState) => ({
                          startHiringOpen: !prevState.startHiringOpen,
                        }));
                      }}
                    >
                      <DropdownToggle className="bg-transparent border-0 p-0 m-0">
                        <Button className={`ms-2 btn btn-sm btn-plus btn-primary`} onClick={() => {}}>
                          <SVG className="me-1" src={plusIcon} />
                          <span>Start Hiring</span>
                        </Button>
                      </DropdownToggle>
                      <StartHiringMenu right>
                        <div className="inner-block" data-testid="header_start_hiring">
                          <DropdownItem tag={Link} to={`${CLIENT}/create-project`}>
                            <SVGIcon src={plusSquareIcon} width={16} height={16} />
                            <P className="p16 m-0 p-0" opactityVal="0.7" lineHeight="16.03">
                              Create a project
                            </P>
                          </DropdownItem>
                          <DropdownItem tag={Link} onClick={handleJobRedirection}>
                            <SVGIcon src={postJobsIcon} width={16} height={16} />
                            <P className="p16 m-0 p-0" opactityVal="0.7" lineHeight="16.03">
                              Post a job
                            </P>
                          </DropdownItem>
                          <DropdownItem tag={Link} to={`${CLIENT}/listings`}>
                            <SVGIcon src={searchIcon} width={16} height={16} />
                            <P className="p16 m-0 p-0" opactityVal="0.7" lineHeight="16.03">
                              Find teams / talents
                            </P>
                          </DropdownItem>

                          <DropdownItem tag={Link} to={`${CLIENT}/create-competition`}>
                            <SVGIcon src={winnerCupNew} width={16} height={16} />
                            <P className="p16 m-0 p-0" opactityVal="0.7" lineHeight="16.03">
                              Host a competition
                            </P>
                          </DropdownItem>
                        </div>
                      </StartHiringMenu>
                    </Dropdown>
                  </HeaderActions>
                  <SecondaryNav>
                    <A className="nav-link me-0" href={clientHelpLink} target="_blank">
                      <SVGIcon src={newHelpIcon} iconColor={`rgb(${primaryDarkNew}, 0.5)`} width={22} height={22} />
                    </A>
                    <Notifications newNotification={get(badgeConfiguration, 'newNotification')} />
                  </SecondaryNav>
                </>
              )}
              <Dropdown isOpen={dropdownOpen} toggle={this.dropdownToggle}>
                <UserProfileToggle
                  style={{
                    marginTop: '0.1rem',
                  }}
                >
                  {profilePicture ? <UserImg src={profilePicture} alt="user-profile" /> : <SVG src={userIcon} />}
                </UserProfileToggle>
                <UserProfileMenu right>
                  <div className="inner-block">
                    <>
                      <DropdownItem tag={Link} to={`${CLIENT}/about-you`}>
                        <SVG src={userIcon} />
                        <FormattedMessage {...messages.menuItemMyProfile} />
                      </DropdownItem>
                      {brand && (
                        <DropdownItem tag={Link} to={`/${formatURL(brand)}`}>
                          <SVG src={clientsIcon} />
                          <FormattedMessage {...messages.menuItemEmployerProfile} />
                        </DropdownItem>
                      )}
                      <DropdownItem tag={Link} to={`${CLIENT}/account-settings`}>
                        <SVG src={settingIcon} />
                        <FormattedMessage {...messages.menuItemAccountSetting} />
                      </DropdownItem>
                    </>
                    <DropdownItem tag={LogoutLink}>
                      <SVG src={logoutIcon} />
                      <FormattedMessage {...messages.menuItemLogout} />
                    </DropdownItem>

                    {proxyType && (
                      <DropdownItem onClick={handleBackToAdmin}>
                        <SVG src={backArrowIcon} />
                        <FormattedMessage {...messages.menuItemBackToAdmin} />
                      </DropdownItem>
                    )}
                  </div>
                </UserProfileMenu>
              </Dropdown>
            </div>
          </>
        )}

        {/* admin profile dropdown */}
        {isSignIn && userType === '4' && (
          <div className="d-flex justify-content-end">
            <Dropdown isOpen={dropdownOpen} toggle={this.dropdownToggle}>
              <UserProfileToggle>
                <SVG src={userIcon} />
              </UserProfileToggle>
              <UserProfileMenu right>
                <div className="inner-block">
                  <DropdownItem tag={Link} to={`${ADMIN}/account-settings`}>
                    <SVG src={settingIcon} />
                    <FormattedMessage {...messages.menuItemAccountSetting} />
                  </DropdownItem>
                  <DropdownItem tag={LogoutLink}>
                    <SVG src={logoutIcon} />
                    <FormattedMessage {...messages.menuItemLogout} />
                  </DropdownItem>
                </div>
              </UserProfileMenu>
            </Dropdown>
          </div>
        )}
        <ProfileCompleteModel
          ref={ProfileCompleteModelRef}
          userType={currentUserType}
          title={applyBriefMessages.ProfileCompleteHeading.defaultMessage}
          description={addBriefMessages.profileCompleteDescription.defaultMessage}
          btnText={containerMessage.BtnProfileComplete.defaultMessage}
          listOption1={addBriefMessages.profileCompleteStep1.defaultMessage}
          listOption2={addBriefMessages.profileCompleteStep2.defaultMessage}
          listOption3={addBriefMessages.profileCompleteStep3.defaultMessage}
          listHeading={addBriefMessages.profileCompleteStepTitle.defaultMessage}
        />
      </>
    );
  }
}

ClientHeader.defaultProps = defaultProps;
ClientHeader.propTypes = propTypes;

export default connect(null, null)(ClientHeader);
