/* eslint-disable no-undef */
import { primaryDarkNew } from 'themes/variables';

import brandingDarkIcon from '../../images/notchup-assets-icon.svg';
import brandingIcon from '../../images/notchup-assets-icon.svg';
import brandDarkImage from '../../images/notchup-assets-icon.svg';
import americanexpressLogo from '../../images/icons/americanexpressLogo.svg';
import genericcardLogo from '../../images/icons/genericcardLogo.svg';
import mastercardLogo from '../../images/icons/mastercardLogo.svg';
import visaLogo from '../../images/icons/visaLogo.svg';
import uploadIcon from '../../images/icons/upload.svg';
import closeIcon from '../../images/icons/close.svg';
import closeDarkIcon from '../../images/icons/closeDark.svg';
import filesIcon from '../../images/icons/files.svg';
import teamIcon from '../../images/icons/team.svg';
import watchIcon from '../../images/icons/watch.svg';
import noteIcon from '../../images/icons/note.svg';
import githubIcon from '../../images/icons/github.svg';
import linkedinIcon from '../../images/icons/linkedin.svg';
import linkedinNoBGIcon from '../../images/icons/linkedin_noBG.svg';
import stackoverflowIcon from '../../images/icons/stackoverflow.svg';
import rocketIcon from '../../images/icons/rocket.svg';
import eyeIcon from '../../images/icons/eye.svg';
import eyeSlashIcon from '../../images/icons/eye-slash.svg';
import certificationsIcon from '../../images/icons/certifications.svg';
import paymentIcon from '../../images/icons/payment.svg';
import professionIcon from '../../images/icons/profession.svg';
import professionIcon2 from '../../images/icons/profession_2.svg';
import softSkillsIcon from '../../images/icons/softskills.svg';
import fileIcon from '../../images/icons/file.svg';
import userIcon from '../../images/icons/user.svg';
import settingIcon from '../../images/icons/settings.svg';
import logoutIcon from '../../images/icons/logout.svg';
import searchIcon from '../../images/icons/search.svg';
import addIcon from '../../images/icons/add.svg';
import winnerCupNew from '../../images/icons/winner-cup-new.svg';
import lightPlusSquareIcon from '../../images/icons/light-plus-square.svg';
import postJobsHome from '../../images/icons/post-jobs-home.svg';
import inviteTalentHome from '../../images/icons/invite-talent-home.svg';
import winnerCupHome from '../../images/icons/winner-cup-home.svg';
import pinIcon from '../../images/icons/pin.svg';
import helpIcon from '../../images/icons/help.svg';
import newHelpIcon from '../../images/icons/newHelp.svg';
import walletIcon from '../../images/icons/wallet.svg';
import notificationIcon from '../../images/icons/bell.svg';
import rightArrowIcon from '../../images/icons/right-angle.svg';
import leftArrowIcon from '../../images/icons/left-angle.svg';
import infoIcon from '../../images/icons/info.svg';
import attachIcon from '../../images/icons/attach.svg';
import circleTickIcon from '../../images/icons/circle-tick.svg';
import deleteIcon from '../../images/icons/trash.svg';
import billIcon from '../../images/icons/bill.svg';
import calendarWithTimeIcon from '../../images/icons/calendar-clock.svg';
import locationIcon from '../../images/icons/location.svg';
import clientsIcon from '../../images/icons/clients.svg';
import dashboardIcon from '../../images/icons/dashboard.svg';
import companiesIcon from '../../images/icons/companies.svg';
import sortIcon from '../../images/icons/sort.svg';
import hamburgerIcon from '../../images/icons/hamburger.svg';
import quotesIcon from '../../images/icons/quotes.svg';
import projectsIcon from '../../images/icons/projects.svg';
import winnerCupThin from '../../images/icons/winner-cup-thin.svg';
import textFileIcon from '../../images/icons/text-file.svg';
import booksIcon from '../../images/icons/books.svg';
import briefcaseIcon from '../../images/icons/briefcase.svg';
import communityIcon from '../../images/icons/community.svg';
import heartIcon from '../../images/icons/heart.svg';
import learningIcon from '../../images/icons/learning.svg';
import perksIcon from '../../images/icons/perks.svg';
import perksandpartnershipIcon from '../../images/icons/perksandpartnership.svg';
import dollarIcon from '../../images/icons/dollar.svg';
import filterIcon from '../../images/icons/filter.svg';
import plusIcon from '../../images/icons/plus.svg';
import expertIcon from '../../images/icons/expert-level.svg';
import builidingIcon from '../../images/icons/builiding.svg';
import sortingIcon from '../../images/icons/sorting.svg';
import professionalIcon from '../../images/icons/professional.svg';
import backArrowIcon from '../../images/icons/back-arrow.svg';
import upArrowIcon from '../../images/icons/chevron-up.svg';
import contractIcon from '../../images/icons/contract.svg';
import contractThinIcon from '../../images/icons/contract-thin.svg';
import postJobsIcon from '../../images/icons/post-jobs.svg';
import projectHomeIcon from '../../images/icons/projects-home.svg';
import shareIcon from '../../images/icons/share.svg';
import downloadIcon from '../../images/icons/download.svg';
import phoneIcon from '../../images/icons/call.svg';
import emailIcon from '../../images/icons/mail.svg';
import paypalIcon from '../../images/icons/paypal.svg';
import payoneerIcon from '../../images/icons/payoneer.svg';
import stripeIcon from '../../images/icons/stripe.svg';
import wiseIcon from '../../images/icons/wise.svg';
import metamaskIcon from '../../images/icons/metamask.svg';
// New theme images/icons
import talentEarningsCard from '../../images/others/talent-earnings-card.png';
import talentTeamsCard from '../../images/others/talent-teams-card.png';
import talentProjectsCard from '../../images/others/talent-projects-card.png';
import clientDashboardCard from '../../images/others/client-dashboard-card.png';
import clientTalentsCard from '../../images/others/client-talents-card.png';
import clientProjectsCard from '../../images/others/client-projects-card.png';
import productTour1 from '../../images/others/productTour1.png';
import talentTour1 from '../../images/others/talentTour1.png';
import talentTour2 from '../../images/others/talentTour2.png';
import productTour2 from '../../images/others/productTour2.png';
import client1 from '../../images/others/client1.png';
import client2 from '../../images/others/client2.png';
import client3 from '../../images/others/client3.png';
import howItWorksBg from '../../images/others/how_it_works_bg.png';
import discProfileImage from '../../images/disc-image.png';
import deelLogo from '../../images/deel-blue.png';
import seedrsLogo from '../../images/seedrs.png';
import resmoLogo from '../../images/resmo.svg';
import seedLegalsLogo from '../../images/seedLegals.png';
import geekstaticLogo from '../../images/geekstatic.svg';
import createlyLogo from '../../images/creately.png';
import veremarkLogo from '../../images/veremark.png';
import tallyLogo from '../../images/tallymarket.png';
import landscapeLogo from '../../images/landscape.png';
import remoteHealthSafetyWingLogo from '../../images/remote-health-safetywing.svg';
import opendoorpolicyLogo from '../../images/opendoorpolicy.jpg';
import balanceCardBG from '../../images/balance-card-bg.png';
import talentDashboardCreateTeam from '../../images/others/talentDashboardCreateTeam.svg';
import talentDashboardCreateTeam2 from '../../images/others/talentDashboardCreateTeam2.svg';
import taskworldLogo from '../../images/taskworld.png';
import debiteLogo from '../../images/debite.png';
import oysterLogo from '../../images/oyster.png';
import ainLogo from '../../images/ain.svg';
import kosyLogo from '../../images/kosy-logo.png';
import openCordLogo from '../../images/opencord-logo.png';
import omnipresentLogo from '../../images/omnipresent.png';
import safetywingLogo from '../../images/safetywing.png';
import linkedinNewIcon from '../../images/icons/linkedin-icon.svg';
import facebookIcon from '../../images/icons/facebook.svg';
import dribbleIcon from '../../images/icons/dribble.svg';
import rightAngleIcon from '../../images/icons/chevron-right.svg';
import leftAngleIcon from '../../images/icons/chevron-left.svg';
import calendarIcon from '../../images/icons/calendar.svg';
import userProfileIcon from '../../images/icons/user-profile.svg';
import editNoteIcon from '../../images/icons/edit-note.svg';
import trashIcon from '../../images/icons/delete.svg';
import cloudUploadIcon from '../../images/icons/cloud-upload.svg';
import bulkUploadIcon from '../../images/icons/bulk-upload.svg';
import paperIcon from '../../images/icons/paper.svg';
import plusSquareIcon from '../../images/icons/plus-square.svg';
import logoPlaceholder from '../../images/icons/image-placeholder.svg';
import logoPlaceholderSM from '../../images/icons/image-placeholder-sm.svg';
import projectPlaceholder from '../../images/icons/project-placeholder.svg';
import projectPlaceholderSM from '../../images/icons/project-placeholder-sm.svg';
import projectPlaceholderXSM from '../../images/icons/project-placeholder-xsm.svg';
import clientIcon from '../../images/icons/client.svg';
import industryIcon from '../../images/icons/industry.svg';
import urlIcon from '../../images/icons/url.svg';
import newTabIcon from '../../images/icons/new-tab.svg';
import earnIcon from '../../images/icons/earn.svg';
import lampIcon from '../../images/icons/lamp.svg';
import payIcon from '../../images/icons/pay.svg';
import backIcon from '../../images/icons/back.svg';
import downArrowIcon from '../../images/icons/chevron-down.svg';
import paperDownloadIcon from '../../images/icons/paper-download.svg';
import paperNotFoundIcon from '../../images/icons/page-not-found.svg';
import searchJobsIcon from '../../images/icons/search-jobs.svg';
import languageIcon from '../../images/icons/language.svg';
import diamondIcon from '../../images/icons/diamond.svg';
import behanceIcon from '../../images/icons/behance.svg';
import earthIcon from '../../images/icons/earth.svg';
import projectEmptyIcon from '../../images/icons/projects-empty.svg';
import JobEmptyIcon from '../../images/icons/jobbrief-empty.svg';
import TimesheetEmptyIcon from '../../images/icons/timesheet-empty.svg';
import MessageIcon from '../../images/icons/message.svg';
import arrowRightIcon from '../../images/icons/arrow-right.svg';
import arrowLeftBackIcon from '../../images/icons/arrow-back-left.svg';
import archiveIcon from '../../images/icons/archive.svg';
import unarchiveIcon from '../../images/icons/unarchive.svg';
import tickIcon from '../../images/icons/tick.svg';
import startTickIconWhite from '../../images/icons/star-tick-white.svg';
import copyIcon from '../../images/icons/copy.svg';
import sendIcon from '../../images/icons/send.svg';
import historyIcon from '../../images/icons/history.svg';
import starTickIcon from '../../images/icons/star-tick.svg';
import talentEmptyIcon from '../../images/icons/talent-empty.svg';
import inviteTalentIcon from '../../images/icons/invite-talent.svg';
import recruiterIcon from '../../images/icons/recruiter.svg';
import ambassadorIcon from '../../images/icons/ambassador.svg';
import errorIcon from '../../images/icons/error.svg';
import warningIcon from '../../images/icons/warning.svg';
import interviewIcon from '../../images/icons/interview.svg';
import discordIcon from '../../images/icons/discord.svg';
import twitterIcon from '../../images/icons/twitter.svg';
import telegramIcon from '../../images/icons/telegram.svg';
import instagramIcon from '../../images/icons/instagram.svg';
import discIcon from '../../images/icons/disc.svg';
import documentsIcon from '../../images/icons/documents.svg';
import DIcon from '../../images/icons/D.svg';
import IIcon from '../../images/icons/I.svg';
import SIcon from '../../images/icons/S.svg';
import CIcon from '../../images/icons/C.svg';
import AIIcon from '../../images/icons/ai-icon.svg';
import importIcon from '../../images/icons/import.svg';
import csvIcon from '../../images/icons/csv.svg';
import pdfIcon from '../../images/icons/pdf.svg';
import unsubscribeIcon from '../../images/icons/unsubscribe.svg';
import codMatrix from '../../images/cod-metrics.gif';
import talentLoader from '../../images/talent-loader.gif';
import avatarTalentHire from '../../images/icons/avatar-talent.svg';
import avatarTeamHire from '../../images/avatar-team.png';
import emptyTalentTeam from '../../images/icons/empty-talent-team.svg';
import searchBackgroundGif from '../../images/search-bg.mp4';
import thumbsUpIcon from '../../images/icons/thumbsup-icon.png';
import thumbsDownIcon from '../../images/icons/thumbsdown-icon.png';
import companyHeaderBg from '../../images/company_details_header.png';
import jobMask from '../../images/job-mask.png';
import teamMask from '../../images/team-mask.png';
import projectMask from '../../images/project-mask.png';
import competitionMask from '../../images/competition-mask.png';
import blueBackground from '../../images/blue-background.jpg';
import winnerCupIcon from '../../images/icons/winner-cup.svg';
import talentImg1 from '../../images/talent-img-1.jpg';
import talentImg2 from '../../images/talent-img-2.jpg';
import talentImg3 from '../../images/talent-img-3.jpg';
import teamImg1 from '../../images/team-img-1.jpg';
import giftIcon from '../../images/icons/gift.svg';
import bookIcon from '../../images/icons/book1.svg';
import winnerwhiteIcon from '../../images/icons/winner-white-icon.svg';
import clockIcon from '../../images/icons/clock.svg';
import earnDollarIcon from '../../images/icons/earn-dollar-icon.svg';
import userImg from '../../images/image 452.jpg';
import moreIcon from '../../images/icons/more.svg';
import flagIcon from '../../images/icons/flag.svg';
import competitionImg from '../../images/competition-banner.jpg';
import talent1 from '../../images/talent-1.jpg';
import teamImg from '../../images/team.jpg';
import certifiedIcon from '../../images/icons/certified.svg';
import participateIcon from '../../images/icons/clients-icon.svg';
import certifiedWhiteIcon from '../../images/icons/certified-white-icon.svg';
import BannerForWebsite from '../../images/Banner_for_website.png';
import BannerForWebsiteMobile from '../../images/Banner_for_mobile.png';
import UpgradePriceBannerBackground from '../../images/Blue_Banner.svg';
import HostCompetitionBannerBackground from '../../images/Purple_Banner.svg';
import UpgradePlanIcon from '../../images/uptrend.svg';
import TrophyIcon from '../../images/trophy-icon.svg';
import teamIconNew from '../../images/icons/teams-icon-new.svg';
import bookmarkIcon from '../../images/icons/Bookmark.svg';
import bookmarkedIcon from '../../images/icons/Bookmarked.svg';
import compareTalentIcon from '../../images/icons/compareTalent.svg';
import addedToCompareIcon from '../../images/icons/comparedTalent.svg';
import talentInterviewIcon from '../../images/icons/interviewTalent.svg';
import talentInterviewedIcon from '../../images/icons/interviewedTalent.svg';
import closeIconBlue from '../../images/icons/closeIconBlue.svg';
import closeIconSecondary from '../../images/icons/closeIconSecondary.svg';

import countryDataList from '../../data/country.json';
import countryStatesList from '../../data/countryStates.json';
import timeXZoneList from '../../data/timeZone.json';
import countryTimeXZoneList from '../../data/CountryTimeZone.json';
import languageDataList from '../../data/languageISO.json';
import skillsList from '../../data/skills.json';
import currencyList from '../../data/currency.json';
import roleList from '../../data/roles.json';

export {
  certifiedWhiteIcon,
  participateIcon,
  taskworldLogo,
  debiteLogo,
  certifiedIcon,
  teamImg,
  talent1,
  competitionImg,
  flagIcon,
  moreIcon,
  userImg,
  clockIcon,
  earnDollarIcon,
  winnerwhiteIcon,
  giftIcon,
  bookIcon,
  talentImg1,
  talentImg2,
  talentImg3,
  teamImg1,
  winnerCupIcon,
  blueBackground,
  thumbsUpIcon,
  thumbsDownIcon,
  searchBackgroundGif,
  ainLogo,
  emptyTalentTeam,
  avatarTalentHire,
  avatarTeamHire,
  codMatrix,
  talentLoader,
  unsubscribeIcon,
  arrowLeftBackIcon,
  upArrowIcon,
  brandDarkImage,
  brandingIcon,
  brandingDarkIcon,
  userProfileIcon,
  uploadIcon,
  closeIcon,
  closeDarkIcon,
  filesIcon,
  teamIcon,
  sortIcon,
  watchIcon,
  noteIcon,
  calendarIcon,
  githubIcon,
  linkedinIcon,
  linkedinNoBGIcon,
  stackoverflowIcon,
  eyeIcon,
  eyeSlashIcon,
  certificationsIcon,
  paymentIcon,
  professionIcon,
  professionIcon2,
  softSkillsIcon,
  fileIcon,
  userIcon,
  settingIcon,
  logoutIcon,
  searchIcon,
  addIcon,
  pinIcon,
  helpIcon,
  newHelpIcon,
  notificationIcon,
  rightArrowIcon,
  leftArrowIcon,
  infoIcon,
  attachIcon,
  circleTickIcon,
  deleteIcon,
  booksIcon,
  briefcaseIcon,
  communityIcon,
  heartIcon,
  learningIcon,
  perksIcon,
  perksandpartnershipIcon,
  dollarIcon,
  filterIcon,
  plusIcon,
  expertIcon,
  builidingIcon,
  sortingIcon,
  professionalIcon,
  backArrowIcon,
  contractIcon,
  contractThinIcon,
  postJobsIcon,
  projectHomeIcon,
  billIcon,
  calendarWithTimeIcon,
  clientsIcon,
  dashboardIcon,
  companiesIcon,
  hamburgerIcon,
  quotesIcon,
  projectsIcon,
  winnerCupThin,
  textFileIcon,
  shareIcon,
  downloadIcon,
  phoneIcon,
  emailIcon,
  linkedinNewIcon,
  facebookIcon,
  dribbleIcon,
  rightAngleIcon,
  editNoteIcon,
  trashIcon,
  cloudUploadIcon,
  paperIcon,
  plusSquareIcon,
  clientIcon,
  industryIcon,
  urlIcon,
  newTabIcon,
  downArrowIcon,
  paperDownloadIcon,
  paperNotFoundIcon,
  searchJobsIcon,
  languageIcon,
  diamondIcon,
  behanceIcon,
  earthIcon,
  arrowRightIcon,
  archiveIcon,
  unarchiveIcon,
  tickIcon,
  startTickIconWhite,
  earnIcon,
  lampIcon,
  payIcon,
  backIcon,
  MessageIcon,
  projectEmptyIcon,
  JobEmptyIcon,
  TimesheetEmptyIcon,
  talentEarningsCard,
  talentTeamsCard,
  talentProjectsCard,
  clientDashboardCard,
  clientTalentsCard,
  clientProjectsCard,
  productTour1,
  talentTour1,
  talentTour2,
  productTour2,
  client1,
  client2,
  client3,
  howItWorksBg,
  deelLogo,
  seedrsLogo,
  resmoLogo,
  oysterLogo,
  kosyLogo,
  openCordLogo,
  tallyLogo,
  landscapeLogo,
  remoteHealthSafetyWingLogo,
  opendoorpolicyLogo,
  omnipresentLogo,
  veremarkLogo,
  safetywingLogo,
  logoPlaceholder,
  logoPlaceholderSM,
  projectPlaceholder,
  projectPlaceholderSM,
  projectPlaceholderXSM,
  copyIcon,
  sendIcon,
  starTickIcon,
  historyIcon,
  talentEmptyIcon,
  inviteTalentIcon,
  recruiterIcon,
  ambassadorIcon,
  errorIcon,
  warningIcon,
  interviewIcon,
  discordIcon,
  telegramIcon,
  twitterIcon,
  instagramIcon,
  lightPlusSquareIcon,
  winnerCupNew,
  postJobsHome,
  inviteTalentHome,
  winnerCupHome,
  leftAngleIcon,
  discIcon,
  discProfileImage,
  documentsIcon,
  locationIcon,
  DIcon,
  IIcon,
  SIcon,
  CIcon,
  AIIcon,
  bulkUploadIcon,
  seedLegalsLogo,
  geekstaticLogo,
  createlyLogo,
  americanexpressLogo,
  genericcardLogo,
  mastercardLogo,
  visaLogo,
  walletIcon,
  balanceCardBG,
  paypalIcon,
  payoneerIcon,
  stripeIcon,
  wiseIcon,
  metamaskIcon,
  importIcon,
  csvIcon,
  pdfIcon,
  companyHeaderBg,
  jobMask,
  teamMask,
  projectMask,
  competitionMask,
  rocketIcon,
  talentDashboardCreateTeam,
  talentDashboardCreateTeam2,
  BannerForWebsite,
  BannerForWebsiteMobile,
  UpgradePriceBannerBackground,
  HostCompetitionBannerBackground,
  TrophyIcon,
  UpgradePlanIcon,
  teamIconNew,
  bookmarkedIcon,
  bookmarkIcon,
  compareTalentIcon,
  addedToCompareIcon,
  talentInterviewIcon,
  talentInterviewedIcon,
  closeIconBlue,
  closeIconSecondary,
};

// Exports
export const LOAD_REPOS = 'boilerplate/App/LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'boilerplate/App/LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'boilerplate/App/LOAD_REPOS_ERROR';
export const LOADING = 'boilerplate/App/LOADING';
export const VERIFY = 'Notchup/App/VERIFY/';
export const VERIFY_PHONE_OTP = 'Notchup/App/VERIFY_PHONE_OTP/';
export const RESET = 'Notchup/App/RESET/';
export const RESEND_CODE = 'Notchup/App/RESEND_CODE/';
export const LOGOUT = 'Notchup/App/LOGOUT';
export const POP_UP_SAGA = 'Notchup/App/POP_UP_SAGA';
export const SHOW_VETTED_BANNER = 'Notchup/App/SHOW_VETTED_BANNER';
export const SHOW_COMPLETE_PROFILE_BANNER = 'Notchup/App/SHOW_COMPLETE_PROFILE_BANNER';

const envs = {
  development: 'DEV',
  production: 'PROD',
};

window.Constants = {
  apiUrl: process.env.REACT_APP_API_URL,
  analyticKey: process.env.REACT_APP_ANALYTIC_KEY,
  gtmCode: process.env.REACT_APP_GTM_CODE,
  gtmWidgetCode: process.env.REACT_APP_GTM_WIDGET_CODE,
  linkedInClientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  linkedInRedirectURI: `${window.location.origin}/linkedin`,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  env: envs[process.env.REACT_APP_NODE_ENV],
};

export const API_URL = Constants.apiUrl;
export const LINKEDIN_CLIENT_ID = Constants.linkedInClientId;
export const LINKEDIN_REDIRECT_URI = Constants.linkedInRedirectURI;
export const ANALYTIC_KEY = Constants.analyticKey;
export const GTM_CODE = Constants.gtmCode;
export const GTM_WIDGET_CODE = Constants.gtmWidgetCode;
export const AMPLITUDE_ANALYTICS_KEY = process.env.REACT_APP_AMPLITUDE_ANALYTICS_KEY;
// marketing-website-urls
export const PRIVACY_POLICY_URL = 'https://www.notchup.com/privacy-policy/';
export const TERMS_CONDITION_URL = 'https://www.notchup.com/terms-and-conditions/';
export const COOKIE_POLICY_URL = 'https://www.notchup.com/cookie-policy/';
export const ACCEPTABLE_POLICY_URL = 'https://www.notchup.com/acceptable-use-policy/';
export const CM_LINKEDIN_URL = 'https://www.linkedin.com/company/codemonk-ai/';
export const CM_DRIBBLE_URL = 'https://dribbble.com/CodeMonk_AI/about/';
export const CM_FACEBOOK_URL = 'https://www.facebook.com/notchup.fow/';
export const CM_SLACK_URL = 'https://notchupteam.slack.com/';
export const TYPEFORM_URL = 'https://innovify.typeform.com/to/OYVH1pOo';
export const DISC_PROFILE_URL = 'https://www.discprofile.com/what-is-disc/disc-styles';
export const COMMUNITY_URL = 'https://discord.com/invite/m4e6BbB9ZA';
export const REFERRAL_TERMS = 'https://www.notchup.com/referral-terms';
export const BUILD_TEAM_FORM_URL = 'https://campaigns.notchup.com/en-gb/build-your-borderless-team';
export const CONTACT_US_FORM_URL = 'https://www.notchup.com/pricing-contact-form';
export const PRICING_URL = 'https://www.notchup.com/pricing';
export const CM_TWITTER_URL = 'https://twitter.com/notchupteam';
export const CM_TELEGRAM_URL = 'https://t.me/codemonk_ai';
export const CM_INSTAGRAM_URL = 'https://www.instagram.com/notchupteam';
export const CM_GITHUB_URL = 'https://github.com/CodeMonk-ai';
export const CM_CONTACT_SALES_URL =
  'https://campaigns.notchup.com/en-gb/build-your-borderless-team?_gl=1*e7qzfu*_ga*MTY2NDk5ODk0NS4xNjQ3NzcwNzgy*_ga_1ZSNY3MHN7*MTY1MTY2MTUyMy45LjEuMTY1MTY2MTU5Ny4w';
export const DEV_URL = 'https://develop.notchup.com';
export const STG_URL = 'https://staging.notchup.com';
export const PROD_URL = 'https://dashboard.notchup.com';

// api endpoints
export const AUTH = '/auth';
export const USER = '/user';
export const CLIENT = '/client';
export const ADMIN = '/admin';
export const RECRUITER = '/recruiter';
export const AMBASSADOR = '/ambassador';
export const AGENCY = '/agency';
export const TALENT_PARTNER = '/talent-partner';
export const TALENT = '/talent';
export const HIRE = '/hire';
export const COMPETITION = '/competition';
export const SIGN_UP_API = '/signup';
export const LOGIN_API = '/signin';
export const FORGOT_PASSWORD_API = '/forgot-password';
export const VERIFY_TOKEN_API = '/verify-token';
export const VERIFY_CREDENTIALS = '/verify-credentials';
export const RESET_PASSWORD_API = '/reset-password';
export const VERIFY_ACCOUNT = '/verify-account';
export const RESEND_OTP_API = '/resend-otp';
export const CHANGE = '/change';
export const REGISTER_TYPE_API = '/register-type';
export const PICTURE_API = '/picture';
export const PERSONAL_DETAIL_API = '/personal-details';
export const PROJECT_DETAIL_API = '/project-details';
export const TALENT_WORK_LOCATIONS = '/work-locations';
export const PAY_DETAIL_API = '/pay-details';
export const USER_DOCUMENTS_API = '/documents';
export const USER_DOCUMENT_API = '/document';
export const WORK_EXPERIENCE_DETAIL_API = '/workexperience-details';
export const EDUCATION_DETAIL_API = '/education-details';
export const PREFERENCES = '/preferences';
export const AVAILABILITY = '/availability';
export const WORK_EXPERIENCE = '/work-experience';
export const WORK_EXPERIENCE_EDIT_API = '/work-experience';
export const EDUCATION_EDIT_API = '/education';
export const CERTIFICATE_EDIT_API = '/certificate';
export const DETAILS = '/details';
export const PROXY_LOGIN = '/proxy-login';
export const SAVE_LATER_API = '/save-later';
export const TALENTS = '/talents';
export const TALENT_LISTING = '/talent-listing';
export const CV = '/cv';
export const LINKEDIN_PROFILE = '/linkedin-profile';
export const EMAIL = '/email';
export const TALENTS_CONVERT_TO_NOTCHUP_API = '/convert-to-notchup';
export const TALENTS_INVITE_API = '/talents-invite';
export const TALENT_INVITE_API = '/talent-invite';
export const CLIENT_INVITE_API = '/client-invite';
export const CERTIFICATE_DETAILS = '/certificate-details';
export const CERTIFICATES = '/certificates';
export const CREDENTIALS = '/credentials';
export const DIRECTORS = '/directors';
export const PREFERENCES_DETAILS_API = '/preference-details';
export const SKILLS_EXPERIENCE_API = '/skill-experience';
export const PROFILE = '/profile';
export const SUMMARY = '/summary';
export const RATE = '/rate';
export const INVITE = '/invite';
export const LIST = '/list';
export const FEEDBACK = '/feedback';
export const COUNT = '/count';
export const TEAM = '/team';
export const KPIS = '/kpis';
export const VERSION2 = '/v2';
export const ABOUT_YOU_API = '/about-you';
export const ABOUT_COMPANY_API = '/about-company';
export const COMPANY_LOCATION_API = '/company-location';
export const LOCATION = '/location';
export const PROFESSIONAL_DETAILS = '/professional-details';
export const PROJECT_API = '/project';
export const PROJECTS = '/projects';
export const STATUS = '/status';
export const PROFESSIONAL_URL_API = '/professional-url';
export const PROFESSIONAL_SKILLS_API = '/skills';
export const INDUSTRIES = '/industries';
export const COMPANY = '/company';
export const COMPANY_CULTURES = '/companycultures';
export const LANGUAGE_SKILLS_API = '/languages';
export const BILLING_API = '/billing';
export const PAYMENT_API = '/payment';
export const PASSWORD_API = '/password';
export const PHONE_NUMBER_API = '/phone-number';
export const VERIFY_NUMBER_API = '/verify-phone-number';
export const PROFILE_DETAIL_API = '/profile';
export const BILLING_DETAIL_API = '/billing';
export const SEARCH_API = '/search';
export const CLIENT_EDIT_BILLING_DETAIL_API = '/edit-billing';
export const CLIENT_EDIT_PAYMENT_DETAIL_API = '/edit-payment';
export const PAY_DETAILS_API = '/pay-details';
export const SEARCH_BY_NAME = '/by-name';
export const INTERVIEW = '/interview';
export const INTERVIEWS = '/interviews';
export const RESPOND = '/respond';
export const NEWS_LETTER = '/news-letter';
export const JOB_POST = '/job-post';
export const SCREENING_QUESTIONS = '/screening-questions';
export const FEATURE = '/feature';
export const ROLE = '/role';
export const PREFERRED_CANDIDATES = '/preferred-candidates';
export const ENGAGEMENT = '/engagement';
export const CERTIFICATIONS = '/certifications';
export const CERTIFICATION = '/certification';
export const APPLY = '/apply';
export const ARCHIVE = '/archive';
export const QUOTE = '/quote';
export const SUBMIT = '/submit';
export const TIMESHEET = '/timesheet';
export const NOTIFICATION = '/notification';
export const MARK_READ = '/mark-read';
export const DOWNLOAD = '/download';
export const BILL = '/bill';
export const CONFIG = '/config';
export const UPLOAD_DOCS = '/upload-documents';
export const VERIFIED_PROFILE_API = '/verified-profile';
export const LOG = '/log';
export const LOGS = '/logs';
export const REFERRAL = '/referral';
export const SKILL_TESTS = '/skillTests';
export const OTHER_SKILL_TESTS = '/otherSkillTests';
export const DISC_TEST = '/discTest';
export const TEAM_WORKING_STYLE_TEST = '/teamWorkingTest';
export const START_TEST = '/start-test';
export const SUBSCRIPTION = '/subscription';
export const MANAGE = '/manage';
export const LIST_PRICES = '/listPrices';
export const LIST_PLANS = '/listPlans';
export const LIST_TRANSACTIONS = '/listTransactions';
export const SUBSCRIBE = '/subscribe';
export const PUBLIC = '/public';
export const SEARCH_TALENT_BY_MAIL = '/search-talent-by-mail';
export const TEAM_LOGO_API = '/logo';
export const TEAM_ASSIGN = '/assign';
export const RECOMMENDED_TALENTS = '/recommended-talents';
export const HIRE_ALL_RECOMMENDED_TALENTS = '/hire-recommended-talents';
export const RECOMMENDED_TALENTS_COMPOSITION = '/recommended-talents-composition';
export const PLANS = '/plans';
export const LIMITS = '/limits';
export const ADD_BRIEF = '/create-brief';
export const NOTIFICATION_PREFERENCE = '/notification-preferences';
export const SUBSCRIBE_NOTIFICATION = '/subscribe-notifications';
export const PRIVATE_LISTS = '/private-lists';
export const JOB_DESCRIPTION = 'job-description';

export const countryData = countryDataList;
export const countryWithStatesData = countryStatesList;
export const timeXZone = timeXZoneList;
export const countryTimeXZone = countryTimeXZoneList;
export const languageData = languageDataList;
export const skillsData = skillsList;
export const currencyData = currencyList;
export const roles = roleList;

export const LOGIN_PAGE_URL = `${TALENT}/login`;
export const SIGNUP_PAGE_URL = `${TALENT}/signup`;
export const TALENT_LOGIN_PAGE_URL = `${TALENT}/login`;
export const TALENT_SIGNUP_PAGE_URL = `${TALENT}/signup`;
export const AGENCY_LOGIN_PAGE_URL = `${AGENCY}/login`;
export const AGENCY_SIGNUP_PAGE_URL = `${AGENCY}/signup`;
export const CLIENT_LOGIN_PAGE_URL = `${CLIENT}/login`;
export const CLIENT_SIGNUP_PAGE_URL = `${CLIENT}/signup`;
export const ADMIN_LOGIN_PAGE_URL = `${ADMIN}/login`;
export const TALENT_PARTNER_LOGIN_PAGE_URL = `${TALENT_PARTNER}/login`;
export const TALENT_PARTNER_SIGNUP_PAGE_URL = `${TALENT_PARTNER}/signup`;
export const TALENT_PARTNER_DASHBOARD = `${TALENT_PARTNER}/dashboard`;
export const TALENT_DASHBOARD = `${TALENT}/dashboard`;
export const CLIENT_DASHBOARD = `${CLIENT}/dashboard`;
export const AGENCY_DASHBOARD = `${AGENCY}/dashboard`;
export const AMBASSADOR_DASHBOARD = `${AMBASSADOR}/dashboard`;
export const AMBASSADOR_LOGIN_PAGE_URL = `${AMBASSADOR}/login`;
export const AMBASSADOR_PAGE_URL = `${AMBASSADOR}/signup`;

export const defaultCountryCode = '44';

export const sampleFile = 'https://dev-codemonk-static-content.s3-eu-west-1.amazonaws.com/Sample+Upload+File.xlsx';
export const instructionFile = 'https://dev-codemonk-static-content.s3-eu-west-1.amazonaws.com/Upload+Instructions.docx';

export const toastMessages = {
  errorMSG: 'Something went wrong. please try again.',
  fileSize: 'File size is less than 10KB',
  maxFileSize: 'File size is greater than 5MB',
  maxQuoteFileSize: 'File size is greater than 50MB',
  maxAgencyQuoteFileSize: 'File size is greater than 50MB',
  fileType: 'PNG, JPEG, JPG, PDF Only',
  quoteFileType: 'PDF, DOCX, ZIP, RAR Only',
};

export const onBoardingSteps = {
  personalDetails: 1,
  professionalDetails: 2,
  projects: 3,
  preferences: 4,
  payment: '7',
};

export const allowedFileTypes = {
  payment: '.png, .jpg, .jpeg, .pdf',
  quoteFile: '.docx, .zip, .rar, .pdf',
};

export const teamPreferenceArray = [
  {
    id: 2,
    label: 'Small team (4-6 members)',
    isChecked: false,
    value: 'small-team',
  },
  {
    id: 3,
    label: 'Medium Team (7-8 members)',
    isChecked: false,
    value: 'medium-team',
  },
  {
    id: 4,
    label: 'Large team (9-10 members)',
    isChecked: false,
    value: 'large-team',
  },
  {
    id: 5,
    label: 'X-Large team (11-16 members)',
    isChecked: false,
    value: 'x-large-team',
  },
];

export const preferenceOfTeamArray = [
  {
    id: 1,
    label: 'Small',
    isChecked: false,
    value: 'small-team',
  },
  {
    id: 2,
    label: 'Medium',
    isChecked: false,
    value: 'medium-team',
  },
  {
    id: 3,
    label: 'Large',
    isChecked: false,
    value: 'large-team',
  },
  {
    id: 4,
    label: 'X-Large',
    isChecked: false,
    value: 'x-large-team',
  },
  {
    id: 5,
    label: 'XX-Large',
    isChecked: false,
    value: 'xx-large-team',
  },
];

export const companyTypeArray = [
  {
    id: 1,
    label: 'Start-up (1 - 20 FTE)',
    isChecked: false,
    value: 'start-up',
  },
  {
    id: 2,
    label: 'Scale-up (21 - 150 FTE)',
    isChecked: false,
    value: 'scale-up',
  },
  {
    id: 3,
    label: 'Medium enterprise (151 - 500 FTE)',
    isChecked: false,
    value: 'medium',
  },
  {
    id: 4,
    label: 'Large enterprise  (500+ FTE)',
    isChecked: false,
    value: 'large',
  },
];

export const preferredProjectTimeArray = [
  {
    id: 1,
    label: 'Short-term (<6 months)',
    isChecked: false,
    value: 'short-term',
  },
  {
    id: 2,
    label: 'Mid-term (6 -12 months)',
    isChecked: false,
    value: 'mid-term',
  },
  {
    id: 3,
    label: 'Long-term (12 months or longer)',
    isChecked: false,
    value: 'long-term',
  },
];

export const showFilterArray = [
  {
    id: 1,
    label: 'All',
    isChecked: false,
    value: 'all',
  },
  {
    id: 2,
    label: 'Active',
    isChecked: false,
    value: 'active',
  },
  {
    id: 3,
    label: 'Archived',
    isChecked: false,
    value: 'archived',
  },
  {
    id: 4,
    label: 'Draft',
    isChecked: false,
    value: 'draft',
  },
];

export const unsubscribeArray = [
  {
    label: 'I no longer want to receive these emails',
    value: -2,
  },
  {
    label: 'I never signed up for this mailing list',
    value: -1,
  },
  {
    label: 'The emails are inappropriate',
    value: 1,
  },
  {
    label: 'The emails are spam and should be reported',
    value: 2,
  },
  {
    label: 'Other (fill in reason below)',
    value: 0,
  },
];

export const assignmentArray = [
  {
    id: 1,
    label: 'Remote',
    isChecked: false,
    value: 'remote-only',
  },
  {
    id: 8,
    label: 'Remote India',
    isChecked: false,
    value: 'remote-india',
  },
  {
    id: 14,
    label: 'Remote Africa',
    isChecked: false,
    value: 'remote-africa',
  },
  {
    id: 9,
    label: 'Remote Europe',
    isChecked: false,
    value: 'remote-europe',
  },
  {
    id: 18,
    label: 'Remote UK',
    isChecked: false,
    value: 'remote-uk',
  },
  {
    id: 10,
    label: 'Remote USA',
    isChecked: false,
    value: 'remote-usa',
  },
  {
    id: 11,
    label: 'Remote Australia',
    isChecked: false,
    value: 'remote-australia',
  },
  {
    id: 12,
    label: 'Remote EMEA',
    isChecked: false,
    value: 'remote-emea',
  },
  {
    id: 13,
    label: 'Remote Eastern Europe',
    isChecked: false,
    value: 'remote-eastern-europe',
  },
  {
    id: 15,
    label: 'Remote LATAM',
    isChecked: false,
    value: 'remote-latam',
  },
  {
    id: 16,
    label: 'Remote APAC',
    isChecked: false,
    value: 'remote-apac',
  },
  {
    id: 17,
    label: 'Remote Canada',
    isChecked: false,
    value: 'remote-canada',
  },
  {
    id: 2,
    label: 'Fully in-office',
    isChecked: false,
    value: 'fully-in-office',
  },
  {
    id: 3,
    label: 'Hybrid',
    isChecked: false,
    value: 'hybrid',
  },
  {
    id: 4,
    label: 'Occasional Site Visit',
    isChecked: false,
    value: 'occational-site-visit',
  },
  {
    id: 5,
    label: 'Short-term onsite (< 6 months)',
    isChecked: false,
    value: 'short-term-onsite',
  },
  {
    id: 6,
    label: 'Mid-term onsite (6 - 12 months)',
    isChecked: false,
    value: 'mid-term-onsite',
  },
  {
    id: 7,
    label: 'Long-term onsite (12 months or longer)',
    isChecked: false,
    value: 'long-term-onsite',
  },
];

export const applicationStatuses = ['Applied', 'Shortlisted', 'Hired', 'Interviewed'];
export const employmentTypeArray = [
  {
    label: 'Freelancer',
    value: 'freelancer-consultant',
  },
  {
    label: 'Permanent',
    value: 'permanent-employee',
  },
];

export const projectPreference = [
  {
    label: 'Full time',
    value: 'fulltime',
  },
  {
    label: 'Part time',
    value: 'parttime',
  },
];

export const partTimeArray = [
  {
    value: 'parttime-weekdays-am',
    label: 'Weekdays-AM',
  },
  {
    value: 'parttime-weekdays-pm',
    label: 'Weekdays-PM',
  },
  {
    value: 'parttime-weekends',
    label: 'Weekends',
  },
];

export const workPreferenceArray = [
  {
    label: 'Full time - Weekdays',
    value: 'fulltime',
  },
  {
    label: 'Weekdays AM',
    value: 'parttime-weekdays-am',
  },
  {
    label: 'Weekdays PM',
    value: 'parttime-weekdays-pm',
  },
  {
    label: 'Weekends',
    value: 'parttime-weekends',
  },
];

export const employmentTypeList = [
  {
    value: 'Fulltime',
    name: 'Full time',
  },
  {
    value: 'Parttime',
    name: 'Part time',
  },
  {
    value: 'Contract',
    name: 'Contract',
  },
  {
    value: 'Temporary',
    name: 'Temporary',
  },
  {
    value: 'Apprenticeship',
    name: 'Apprenticeship',
  },
  {
    value: 'Freelancer',
    name: 'Freelancer',
  },
  {
    value: 'Internship',
    name: 'Internship',
  },
  {
    value: 'Self Employed',
    name: 'Self Employed',
  },
];

export const genderArray = [
  {
    value: 'Male',
    name: 'Male',
  },
  {
    value: 'Female',
    name: 'Female',
  },
  {
    value: 'Other',
    name: 'Non-binary',
  },
  {
    value: 'Undisclosed',
    name: 'Prefer not to say',
  },
];

export const educationDegree = [
  {
    value: 'Master’s or Higher',
    name: 'Master’s or Higher',
  },
  {
    value: 'Bachelor’s',
    name: 'Bachelor’s',
  },
  {
    value: 'Associate’s',
    name: 'Associate’s',
  },
  {
    value: 'Some College',
    name: 'Some College',
  },
  {
    value: 'High School Diploma/GED',
    name: 'High School Diploma/GED',
  },
];

export const yearsOfExperienceArray = [
  {
    value: 'Distinguished - 15+ yrs',
    name: 'Distinguished - 15+ yrs',
    label: 'Distinguished',
  },
  {
    value: 'Principal - 12 - 15 yrs',
    name: 'Principal - 12 - 15 yrs',
    label: 'Principal',
  },
  {
    value: 'Senior - 8 - 12 yrs',
    name: 'Senior - 8 - 12 yrs',
    label: 'Senior',
  },
  {
    value: 'Intermediate - 5 - 8 yrs',
    name: 'Intermediate - 5 - 8 yrs',
    label: 'Intermediate',
  },
  {
    value: 'Junior - 2 - 5 yrs',
    name: 'Junior - 2 - 5 yrs',
    label: 'Junior',
  },
  {
    value: 'Beginner - 0 - 2 yrs',
    name: 'Beginner - 0 - 2 yrs',
    label: 'Beginner',
  },
];
export const roleYears = yearsOfExperienceArray;

export const customStyles = {
  rows: {
    style: {
      minHeight: '69px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontSize: '16px',
      fontFamily: 'GT-Walsheim-Pro-Bold',
      letterSpacing: '0.3px',
      minHeight: '69px',
    },
  },
  cells: {
    style: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontSize: '16px',
      letterSpacing: '0.3px',
    },
  },
};

export const transactionCustomStyles = {
  rows: {
    style: {
      minHeight: '69px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontSize: '16px',
      fontFamily: 'GT-Walsheim-Pro-Bold',
      letterSpacing: '0.3px',
      minHeight: '69px',
      '&:first-child': {
        padding: '0px !important',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontSize: '16px',
      letterSpacing: '0.3px',
      '&:nth-child(2)': {
        maxWidth: '16.3%',
      },
      '&:nth-child(3)': {
        maxWidth: '30%',
      },
      '&:nth-child(4)': {
        maxWidth: '16.2%',
      },
      '&:nth-child(5)': {
        maxWidth: '16.1%',
      },
    },
  },
};

export const newCustomStylesForGrid = {
  rows: {
    style: {
      minHeight: '54px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '15px',
      paddingRight: '15px',
      fontSize: '16px',
      fontFamily: 'GT-Walsheim-Pro-Regular',
      letterSpacing: '0.3px',
      minHeight: '58px',
      lineHeight: '18px',
      width: '100%',
      color: `rgb(${primaryDarkNew})`,
      '&:first-child': {
        paddingLeft: '20px !important',
      },
      '&:last-child': {
        paddingRight: '25px !important',
      },
    },
  },
  cells: {
    style: {
      width: '175px',
      paddingLeft: '15px',
      paddingRight: '15px',
      fontSize: '16px',
      letterSpacing: '0.3px',
      color: `rgba(${primaryDarkNew}, 0.5)`,
    },
  },
};

export const talentStatusArray = [
  {
    label: 'Inactive',
    value: 0,
  },
  {
    label: 'Active',
    value: 1,
  },
];

export const discProfileArray = [
  {
    label: 'D - Style',
    value: 'D - Style',
  },
  {
    label: 'I - Style',
    value: 'I - Style',
  },
  {
    label: 'S - Style',
    value: 'S - Style',
  },
  {
    label: 'C - Style',
    value: 'C - Style',
  },
  {
    label: 'DI - Style',
    value: 'DI - Style',
  },
  {
    label: 'ID - Style',
    value: 'ID - Style',
  },
  {
    label: 'DC - Style',
    value: 'DC - Style',
  },
  {
    label: 'CD - Style',
    value: 'CD - Style',
  },
  {
    label: 'IS - Style',
    value: 'IS - Style',
  },
  {
    label: 'SI - Style',
    value: 'SI - Style',
  },
  {
    label: 'SC - Style',
    value: 'SC - Style',
  },
  {
    label: 'CS - Style',
    value: 'CS - Style',
  },
];

export const teamWorkingArray = [
  {
    label: 'Team Player',
    value: 'Team Player',
  },
  {
    label: 'Individual Contributors',
    value: 'Individual Contributors',
  },
  {
    label: 'Efficiency Experts',
    value: 'Efficiency Experts',
  },
];

export const HIRING_ACTION_STATUS = {
  applicant: 1,
  interview: 2,
  shortlist: 3,
  hire: 4,
  reject: 5,
};

export const routes = [
  {
    paths: [
      { path: `${TALENT}`, exact: true },
      { path: `${TALENT}/`, exact: true },
      { path: TALENT_DASHBOARD, exact: true },
    ],
    headerTitle: 'Dashboard',
    key: 'Talent_Dashboard',
  },
  {
    paths: [{ path: `${TALENT}/my-profile`, exact: true }],
    headerTitle: 'My profile',
    key: 'Talent_MyProfilePage',
  },
  {
    paths: [{ path: `${TALENT}/account-settings`, exact: true }],
    headerTitle: 'Account settings',
    key: 'AccountSettingsPage',
  },
  {
    paths: [{ path: `${TALENT}/timesheets`, exact: true }],
    headerTitle: 'Timesheets',
    key: 'Talent_Timesheets',
  },
  {
    paths: [{ path: `${TALENT}/job-briefs`, exact: true }],
    headerTitle: 'Briefs',
    key: 'TalentBriefs',
  },
  {
    paths: [{ path: `${TALENT}/validate-skills`, exact: true }],
    headerTitle: 'Verify your skills',
    key: 'ValidateSkills',
  },
  {
    paths: [{ path: `${TALENT}/knowledge-base`, exact: true }],
    headerTitle: 'Knowledge base',
    key: 'TalentKnowledgeBase',
  },
  {
    paths: [{ path: `${TALENT}/career-paths`, exact: true }],
    headerTitle: 'Career paths',
    key: 'TalentCareerPath',
  },
  {
    paths: [{ path: `${TALENT}/learning-development`, exact: true }],
    headerTitle: 'Learning development',
    key: 'TalentLearningDevelopment',
  },

  {
    paths: [{ path: `${TALENT}/wellbeing`, exact: true }],
    headerTitle: 'Wellbeing',
    key: 'TalentWellbeing',
  },
  {
    paths: [{ path: `${TALENT}/invoices`, exact: true }],
    headerTitle: 'Inovice',
    key: 'Talent_Invoices',
  },
  {
    paths: [{ path: `${TALENT}/my-projects`, exact: true }],
    headerTitle: 'Projects',
    key: 'Talent_MyProjects',
  },
  {
    paths: [{ path: `${TALENT}/my-teams`, exact: true }],
    headerTitle: 'Teams',
    key: 'Talent_MyTeams',
  },
  {
    paths: [{ path: `${TALENT}/team/:profileId`, exact: false }],
    headerTitle: 'Teams',
    key: 'Talent_TeamsDetails',
  },
  {
    paths: [{ path: `${TALENT}/project-detail/:projectID`, exact: false }],
    headerTitle: 'Projects',
    key: 'MyProjectDetail',
  },
  {
    paths: [{ path: `${TALENT}/clients`, exact: true }],
    headerTitle: 'Clients',
    key: 'TalentReferralClients',
  },
  {
    paths: [{ path: `${TALENT}/talents`, exact: true }],
    headerTitle: 'Talents',
    key: 'TalentReferralTalent',
  },
  // {
  //   paths: [{ path: `${TALENT}/wallet`, exact: true }],
  //   headerTitle: 'Wallet',
  //   key: 'TalentWallet',
  // },
  // {
  //   paths: [{ path: `${TALENT}/wallet-transactions`, exact: true }],
  //   headerTitle: 'Wallet',
  //   key: 'TalentWalletTransactions',
  // },
  {
    paths: [{ path: `${TALENT}/competitions`, exact: false }],
    headerTitle: 'Competitions',
    key: 'TalentCompetitions',
  },
  {
    paths: [{ path: `${TALENT}/:username`, exact: false }],
    headerTitle: 'Talent Profile',
    key: 'ProfilePage',
  },
  {
    paths: [{ path: `${TALENT}/referrals`, exact: true }],
    headerTitle: 'Refer and Earn',
    key: 'TalentReferEarn',
  },
  {
    paths: [
      { path: `${AGENCY}`, exact: true },
      { path: `${AGENCY}/`, exact: true },
      { path: AGENCY_DASHBOARD, exact: true },
    ],
    headerTitle: 'Dashboard',
    key: 'Agency_Dashboard',
  },
  {
    paths: [{ path: `${AGENCY}/agency-profile`, exact: true }],
    headerTitle: 'My profile',
    key: 'AgencyProfilePage',
  },
  {
    paths: [{ path: `${AGENCY}/agency-account-settings`, exact: true }],
    headerTitle: 'Account settings',
    key: 'AgencyAccountSettingsPage',
  },
  {
    paths: [{ path: `${AGENCY}/quotes`, exact: true }],
    headerTitle: 'Quotes',
    key: 'AgencyQuotes',
  },
  {
    paths: [{ path: `${AGENCY}/quote-detail/:quoteID`, exact: false }],
    headerTitle: 'Quotes',
    key: 'AgencyQuoteDetail',
  },
  {
    paths: [{ path: `${AGENCY}/agency-projects`, exact: true }],
    headerTitle: 'Projects',
    key: 'Agency_MyProjects',
  },
  {
    paths: [{ path: `${AGENCY}/agency-project-detail/:projectID`, exact: false }],
    headerTitle: 'Projects',
    key: 'AgencyProjectDetail',
  },
  {
    paths: [{ path: `${AGENCY}/timesheets`, exact: true }],
    headerTitle: 'Timesheets',
    key: 'Agency_Timesheets',
  },
  {
    paths: [{ path: `${AGENCY}/invoices`, exact: true }],
    headerTitle: 'Invoices',
    key: 'Agency_Invoices',
  },
  {
    paths: [{ path: `${AGENCY}/agency-statements`, exact: true }],
    headerTitle: 'Statements',
    key: 'AgencyStatements',
  },
  {
    paths: [{ path: `${AGENCY}/resources`, exact: true }],
    headerTitle: 'Resources',
    key: 'AgencyMyTeam',
  },
  {
    paths: [{ path: `${AGENCY}/agency-planning`, exact: true }],
    headerTitle: 'Planning',
    key: 'AgencyPlanning',
  },
  {
    paths: [{ path: `${AGENCY}/talent-profile/:talentID`, exact: false }],
    headerTitle: 'Talents',
    key: 'Agency_MyProfilePage',
  },
  {
    paths: [{ path: `${AGENCY}/job-briefs`, exact: true }],
    headerTitle: 'Resourcing',
    key: 'AgencyBriefs',
  },
  {
    paths: [{ path: `${AGENCY}/talent`, exact: true }],
    headerTitle: 'Talent',
    key: 'AgencyAddEditTalent',
  },
  {
    paths: [
      { path: `${CLIENT}`, exact: true },
      { path: `${CLIENT}/`, exact: true },
      { path: CLIENT_DASHBOARD, exact: true },
    ],
    headerTitle: 'Dashboard',
    key: 'Client_Dashboard',
  },
  {
    paths: [{ path: `${CLIENT}/start-project`, exact: true }],
    headerTitle: 'Start projects',
    key: 'StartProjectPage',
  },
  {
    paths: [{ path: `${CLIENT}/request-received`, exact: true }],
    headerTitle: 'Start projects',
    key: 'RequestReceivedPage',
  },
  {
    paths: [
      { path: `${CLIENT}/talent-listing`, exact: true },
      { path: `${CLIENT}/listings`, exact: true },
      { path: `${ADMIN}/teams`, exact: true },
      { path: `${ADMIN}/teams/`, exact: true },
    ],
    headerTitle: 'Find teams or talents',
    key: 'TalentListingPage',
  },
  {
    paths: [{ path: `${CLIENT}/my-profile`, exact: true }],
    headerTitle: 'My profile',
    key: 'ClientProfile',
  },
  {
    paths: [{ path: `/:name`, exact: false }],
    headerTitle: 'Profile',
    key: 'ClientProfilePage',
  },
  {
    paths: [{ path: `${CLIENT}/talent-profile/:talentID`, exact: false }],
    headerTitle: 'Talents',
    key: 'Client_MyProfilePage',
  },
  {
    paths: [{ path: `${CLIENT}/account-settings`, exact: true }],
    headerTitle: 'Account settings',
    key: 'ClientAccountSettingsPage',
  },
  {
    paths: [{ path: `${CLIENT}/job-briefs`, exact: true }],
    headerTitle: 'Briefs',
    key: 'ClientBrief',
  },
  {
    paths: [{ path: `${CLIENT}/projects`, exact: true }],
    headerTitle: 'Projects',
    key: 'ClientProjects',
  },
  {
    paths: [{ path: `${CLIENT}/schedule-interview`, exact: true }],
    headerTitle: 'Schedule Interview',
    key: 'ClientScheduleInterview',
  },
  {
    paths: [
      { path: `${CLIENT}/interviews`, exact: true },
      { path: `${TALENT}/interviews`, exact: true },
    ],
    headerTitle: 'Interviews',
    key: 'ClientInterviews',
  },
  {
    paths: [
      { path: `${CLIENT}/interviews/:id`, exact: false },
      { path: `${TALENT}/interviews/:id`, exact: false },
    ],
    headerTitle: 'Interview details',
    key: 'ClientInterviewDetailPage',
  },
  {
    paths: [
      { path: `${CLIENT}/interviews/:id/feedback`, exact: false },
      // { path: `${TALENT}/interviews/:id`, exact: false },
    ],
    headerTitle: 'Interview feedback',
    key: 'InterviewFeedback',
  },
  {
    paths: [{ path: `${CLIENT}/create-project`, exact: true }],
    headerTitle: '',
    key: 'ClientCreateProject',
  },
  {
    paths: [{ path: `${CLIENT}/edit-project/:projectId`, exact: false }],
    headerTitle: '',
    key: 'ClientEditProject',
  },
  {
    paths: [{ path: `${CLIENT}/talents`, exact: true }],
    headerTitle: 'Talents',
    key: 'ClientTalents',
  },
  {
    paths: [{ path: `${CLIENT}/billing`, exact: true }],
    headerTitle: 'Billing',
    key: 'ClientBilling',
  },
  {
    paths: [{ path: `${CLIENT}/contracts`, exact: true }],
    headerTitle: 'Contracts',
    key: 'ClientContracts',
  },
  {
    paths: [{ path: `${CLIENT}/payments`, exact: true }],
    headerTitle: 'Payments',
    key: 'ClientPayments',
  },
  {
    paths: [{ path: `${CLIENT}/create-brief`, exact: true }],
    headerTitle: 'Create Brief',
    key: 'ClientCreateBriefPage',
  },
  {
    paths: [{ path: `${CLIENT}/project-detail/:projectID`, exact: false }],
    headerTitle: 'Projects',
    key: 'ClientProjectDetailPage',
  },
  {
    paths: [{ path: `${CLIENT}/competitions`, exact: false }],
    headerTitle: 'Competitions',
    key: 'ClientCompetitions',
  },
  {
    paths: [{ path: `${CLIENT}/create-competition`, exact: false }],
    headerTitle: 'Competitions',
    key: 'ClientCreateCompetition',
  },
  {
    paths: [{ path: `/:brandName/competition-detail/:competitionID`, exact: false }],
    headerTitle: '',
    key: 'ClientCompetitionDetailPage',
  },
  {
    paths: [{ path: `${CLIENT}/edit-competition/:competitionID`, exact: false }],
    headerTitle: '',
    key: 'ClientEditCompetition',
  },
  {
    paths: [{ path: `${CLIENT}/hire-team`, exact: true }],
    headerTitle: 'Hire Team',
    key: 'ClientHireTeam',
  },
  {
    paths: [{ path: `${CLIENT}/timesheets`, exact: true }],
    headerTitle: 'Timesheets',
    key: 'Client_Timesheets',
  },
  {
    paths: [
      { path: `${CLIENT}/perks`, exact: true },
      { path: `${TALENT}/perks`, exact: true },
    ],
    headerTitle: 'Perks & Partnerships',
    key: 'ClientPerks',
  },
  {
    paths: [{ path: `${ADMIN}/company-listing`, exact: true }],
    headerTitle: 'Companies',
    key: 'AdminCompanyListing',
  },
  {
    paths: [{ path: `${ADMIN}/create-project`, exact: true }],
    headerTitle: '',
    key: 'AdminCreateProject',
  },
  {
    paths: [{ path: `${ADMIN}/edit-project/:projectId`, exact: true }],
    headerTitle: '',
    key: 'AdminEditProject',
  },
  {
    paths: [
      { path: `${ADMIN}`, exact: true },
      { path: `${ADMIN}/`, exact: true },
      { path: `${ADMIN}/dashboard`, exact: true },
    ],
    headerTitle: 'Dashboard',
    key: 'Admin_Dashboard',
  },
  {
    paths: [{ path: `${ADMIN}/projects`, exact: true }],
    headerTitle: 'Projects',
    key: 'AdminProjects',
  },
  {
    paths: [{ path: `${ADMIN}/job-briefs`, exact: true }],
    headerTitle: 'Briefs',
    key: 'Admin_Brief',
  },
  {
    paths: [{ path: `${ADMIN}/interviews`, exact: true }],
    headerTitle: 'Interviews',
    key: 'InterviewRequests',
  },
  {
    paths: [{ path: `${ADMIN}/quotes`, exact: true }],
    headerTitle: 'Quotes',
    key: 'Quotes',
  },
  {
    paths: [{ path: `${ADMIN}/talents`, exact: true }],
    headerTitle: 'Talents',
    key: 'AdminTalents',
  },
  {
    paths: [{ path: `${ADMIN}/timesheets`, exact: true }],
    headerTitle: 'Timesheets',
    key: 'Admin_Timesheets',
  },
  {
    paths: [{ path: `${ADMIN}/clients`, exact: true }],
    headerTitle: 'Clients',
    key: 'AdminClients',
  },
  {
    paths: [{ path: `${ADMIN}/agencies`, exact: true }],
    headerTitle: 'Agencies',
    key: 'AdminAgencies',
  },
  {
    paths: [{ path: `${ADMIN}/project-detail/:projectID`, exact: false }],
    headerTitle: 'Projects',
    key: 'Admin_ProjectDetailPage',
  },
  {
    paths: [{ path: `${ADMIN}/client-detail/:clientID`, exact: false }],
    headerTitle: 'Clients',
    key: 'ClientDetailPage',
  },
  {
    paths: [{ path: `${ADMIN}/agency-detail/:agencyID`, exact: false }],
    headerTitle: 'Agencies',
    key: 'AgencyDetailPage',
  },
  {
    paths: [{ path: `${ADMIN}/interview-detail/:interviewID`, exact: false }],
    headerTitle: 'Interviews',
    key: 'InterviewDetailPage',
  },
  {
    paths: [{ path: `${ADMIN}/talent-profile/:talentID`, exact: false }],
    headerTitle: 'Talents',
    key: 'Admin_MyProfilePage',
  },
  {
    paths: [{ path: `${ADMIN}/quote-detail/:quoteID`, exact: false }],
    headerTitle: 'Quotes',
    key: 'QuoteDetail',
  },
  {
    paths: [{ path: `${ADMIN}/referrals`, exact: true }],
    headerTitle: 'Referrals',
    key: 'Referrals',
  },
  {
    paths: [{ path: `${ADMIN}/talent-partners`, exact: true }],
    headerTitle: 'Talent partners',
    key: 'TalentPartners',
  },
  {
    paths: [{ path: `${ADMIN}/talent-partner-detail/:recruiterId`, exact: false }],
    headerTitle: 'Talent partners',
    key: 'TalentPartnersDetailPage',
  },
  {
    paths: [{ path: `${ADMIN}/ambassadors`, exact: true }],
    headerTitle: 'Ambassadors',
    key: 'Ambassadors',
  },
  {
    paths: [{ path: `${ADMIN}/ambassador-detail/:ambassadorId`, exact: false }],
    headerTitle: 'Ambassador',
    key: 'AmbassadorDetailPage',
  },
  {
    paths: [{ path: `${ADMIN}/account-settings`, exact: true }],
    headerTitle: 'Account settings',
    key: 'AdminAccountSettingsPage',
  },

  {
    paths: [{ path: `${ADMIN}/add-company`, exact: true }],
    headerTitle: '',
    key: 'AdminAddACompanyPage',
  },
  {
    paths: [{ path: `${ADMIN}/add-company/invite`, exact: true }],
    headerTitle: '',
    key: 'AdminInviteClientPage',
  },
  {
    paths: [
      { path: `${TALENT_PARTNER}`, exact: true },
      { path: `${TALENT_PARTNER}/`, exact: true },
      { path: `${TALENT_PARTNER}/dashboard`, exact: true },
    ],
    headerTitle: 'Dashboard',
    key: 'RecruiterDashboard',
  },
  {
    paths: [{ path: `${TALENT_PARTNER}/job-briefs`, exact: true }],
    headerTitle: 'Jobs',
    key: 'RecruiterBriefs',
  },
  {
    paths: [
      { path: `${AMBASSADOR}`, exact: true },
      { path: `${AMBASSADOR}/`, exact: true },
      { path: `${AMBASSADOR}/dashboard`, exact: true },
    ],
    headerTitle: 'Dashboard',
    key: 'AmbassadorDashboard',
  },
  {
    paths: [{ path: `${AMBASSADOR}/talents`, exact: true }],
    headerTitle: 'Talents',
    key: 'AmbassadorTalents',
  },

  {
    paths: [{ path: `${AMBASSADOR}/clients`, exact: true }],
    headerTitle: 'Clients',
    key: 'AmbassadorClients',
  },
  {
    paths: [{ path: `${TALENT_PARTNER}/talents`, exact: true }],
    headerTitle: 'Talents',
    key: 'RecruiterTalent',
  },

  {
    paths: [{ path: '/:brandName/jobs/:jobTitle/:jobID', exact: false }],
    headerTitle: 'Jobs',
    key: 'JobBriefPage',
  },
  {
    paths: [{ path: `/:role/:username/referral/:userId`, exact: false }],
    headerTitle: 'Referral Link',
    key: 'ReferralLink',
  },
];

export const TINY_MCE_API_KEY = 'xp4ybin04jgu7fahte3g258n9drgg4icta5ntmnjz9hwo6ay';
// export const TINY_MCE_API_KEY = 'a6jo4gyr157bqvpfoavtcgemxwwocz17x3eq0bvmnt2wqw52';
export const TALENT_REFERRAL_UTM = 'utm_source=Dashboard+Referral&utm_medium=Dashboard+Referral+-+Talent&utm_campaign=talent_referral';
export const CLIENT_REFERRAL_UTM = 'utm_source=Dashboard+Referral&utm_medium=Dashboard+Referral+-+Client&utm_campaign=client_referral';

export const yearsOfExperienceLable = {
  Beginner: '1+',
  Junior: '2+',
  Intermediate: '5+',
  Senior: '8+',
  Principal: '12+',
  Distinguished: '15+',
};

export const signUpUserAccountType = [
  {
    label: 'Talent - Looking for remote work',
    value: 'talent',
  },
  {
    label: 'Employer - Looking to hire remote teams',
    value: 'client',
  },
];

export const talentHelpLink = 'https://help.notchup.com/en/collections/3179864-for-talents';
export const clientHelpLink = 'https://help.notchup.com/en/collections/3179863-for-employers';
export const agencyHelpLink = 'https://help.notchup.com/en/collections/3179864-for-talents';
