/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import { currencyData } from 'containers/App/constants';
import { Selects } from 'components';
import { CombinedFields } from 'containers/Auth/PersonalDetails/style';
import { Field, reduxForm } from 'redux-form';
import { renderField } from 'utils/Fields';

const currencyMap = {
  USD: '$',
  GBP: '£',
  EUR: '€',
};

const InvitedTalentRateFieldComponent = ({ talentItem, updateTalentRate, handleStateChange }) => {
  const [newCurrency, setNewCurrency] = useState({ label: currencyMap[talentItem?.currency] || '$', value: talentItem?.currency || 'USD' });
  const [newRate, setNewRate] = useState(talentItem?.ratePerHour);
  const inputRef = useRef(null);
  const rateField = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [newCurrency]);

  const handleBlur = () => {
    updateTalentRate({
      talentUserId: talentItem.talentUserId,
      ratePerHour: parseFloat(newRate),
      currency: newCurrency?.value,
      ...(talentItem?._id && { projectId: talentItem._id }),
    });
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
    if (e.key === 'Escape') {
      handleStateChange('updateTalentRateId', null);
    }
  };
  return (
    <CombinedFields className="d-flex" key={talentItem?.profileId} ref={rateField}>
      <Field
        className="newSelectStyle"
        name={`currency-${talentItem.profileId}`}
        component={Selects}
        options={currencyData.map((code) => ({
          label: code.symbol,
          value: code.code,
        }))}
        defaultValue={newCurrency}
        searchable={false}
        onChange={(e) => {
          setNewCurrency(e);
        }}
        data-testid={`currency-${talentItem.profileId}`}
      />
      <Field
        className="inputStyle"
        name={`ratePerHour-${talentItem.profileId}`}
        type="number"
        component={renderField}
        placeholder="00.00"
        input={{
          ref: inputRef,
          onBlur: () => {
            handleBlur();
          },
          onKeyUp: handleEnterPress,
          onChange: (e) => {
            setNewRate(e.target.value);
          },
          defaultValue: newRate,
          'data-testid': `ratePerHour-${talentItem.profileId}`,
        }}
      />
    </CombinedFields>
  );
};

export const InvitedTalentRateField = reduxForm({
  form: 'InvitedTalentRateForm',
})(InvitedTalentRateFieldComponent);
