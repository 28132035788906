import React from 'react';
import { put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { VALIDATION } from 'utils/constants';
import AuthTokenService from 'utils/AuthTokenService';
import StorageService from 'utils/StorageService';
import { getUserRoleFromURL, gtm } from 'utils/Helper';
import { storeApiSignupStep } from 'containers/Auth/utils';
import { repoLoadingError, reset } from 'containers/App/actions';
import ToastifyMessage from 'components/ToastifyMessage';
import {
  redirectPageURL,
  agencyRedirectPageURL,
  clientRedirectPageURL,
  recruiterRedirectPageURL,
  ambassadorRedirectPageURL,
  storeUserProfileId,
} from 'containers/App/utils';
import { ADMIN } from 'containers/App/constants';
import { setDefaultRoleBasedBriefFilter } from 'containers/Talent/Briefs/utils';

export function* login(data) {
  try {
    const { payload: log } = data;
    const userType = get(log, 'data.role', '');

    if (get(log, 'status') === 1) {
      // GTM
      gtm({ action: 'Button Click', label: 'login', category: `${getUserRoleFromURL()} Portal`, directGA: true });

      AuthTokenService.storeToken(get(log, 'data.token'));
      localStorage.setItem('profilePicture', get(log, 'data.profilePicture', ''), { hash: true });
      StorageService.set('firstName', get(log, 'data.firstName', ''), { hash: true });
      StorageService.set('lastName', get(log, 'data.lastName', ''), { hash: true });

      const userBillingDetailsObj = {
        currency: get(log, 'data.currency', ''),
        ratePerHour: get(log, 'data.ratePerHour', ''),
      };
      StorageService.set('userBillingDetails', JSON.stringify(userBillingDetailsObj), { hash: true });
      StorageService.set('userEmail', get(log, 'data.email', ''), { hash: true });

      const registerType = get(log, 'data.registerType', 'freelancer');
      StorageService.set('userType', userType, { hash: true });
      StorageService.set('registerType', registerType, { hash: true });
      const profileId = get(log, 'data.profileId');
      const userId = get(log, 'data.userId');
      storeUserProfileId(profileId, userId);
      yield put(reset());
      let redirectPage = '';

      storeApiSignupStep(get(log, 'data.signupStep'));
      if (userType === 1) {
        // set talent's job-bried role-based filter
        setDefaultRoleBasedBriefFilter(log.data);

        let signupStep;
        signupStep = get(log, 'data.signupStep') === 0.1 ? 1 : get(log, 'data.signupStep', 0) + 1;

        const requestChangeEmail = get(log, 'data.requestChangeEmail') ? get(log, 'data.requestChangeEmail') : 0;
        if (registerType === 'agency') {
          signupStep = signupStep >= 6 ? 8 : signupStep;
        }
        StorageService.set('signupStep', signupStep, { hash: true });
        StorageService.set('userVersion', get(log, 'data.version'), { hash: true });
        StorageService.set('requestChangeEmail', requestChangeEmail, { hash: true });

        redirectPage = redirectPageURL(signupStep);
      } else if (userType === 3) {
        const signupStep = get(log, 'data.signupStep') === 0.1 ? 1 : get(log, 'data.signupStep', 0);
        StorageService.set('signupStep', signupStep, { hash: true });
        StorageService.set('agencyLogo', get(log, 'data.trading.logo', ''), { hash: true });

        redirectPage = agencyRedirectPageURL(signupStep);
      } else if (userType === 2) {
        const isSubscribed = get(log, 'data.subscribed') ? get(log, 'data.subscribed') : 0;
        StorageService.set('subscribed', isSubscribed, { hash: true });
        const currentSignupStep = get(log, 'data.signupStep', '');
        const signupStep = currentSignupStep >= 3 ? 3 : currentSignupStep;
        StorageService.set('signupStep', signupStep, { hash: true });
        StorageService.set('clientID', get(log, 'data._id'), { hash: true });
        StorageService.set('clientBrand', get(log, 'data.brand', ''), { hash: true });
        redirectPage = clientRedirectPageURL(3);
      } else if (userType === 5) {
        const signupStep = get(log, 'data.signupStep') === 0.1 ? 0 : get(log, 'data.signupStep', 0);
        StorageService.set('signupStep', signupStep, { hash: true });
        StorageService.set('recruiterId', get(log, 'data._id'), { hash: true });

        redirectPage = recruiterRedirectPageURL(signupStep);
      } else if (userType === 6) {
        const signupStep = get(log, 'data.signupStep') === 0.1 ? 0 : get(log, 'data.signupStep', 0);
        StorageService.set('signupStep', signupStep, { hash: true });
        StorageService.set('ambassadorId', get(log, 'data._id'), { hash: true });

        redirectPage = ambassadorRedirectPageURL(signupStep);
      } else if (userType === 4) {
        redirectPage = ADMIN;
      }
      const redirectToPage = StorageService.get('redirectToPage');
      if (redirectToPage) {
        redirectPage = redirectToPage;
        StorageService.delete('redirectToPage');
      }
      yield put(push({ pathname: redirectPage }));
    } else {
      yield put(reset());
      toast.error(<ToastifyMessage message={get(log, 'message')} type="error" />, { className: 'Toast-error' });
      yield put(repoLoadingError(get(log, 'message')));
    }
  } catch (err) {
    yield put(reset());
    toast.error(<ToastifyMessage message={VALIDATION.wentWrong} type="error" />, { className: 'Toast-error' });
    yield put(repoLoadingError(err));
  }
}

export default function* talentLoginForm() {
  yield takeLatest('LINKEDIN_LOGIN', login);
}
