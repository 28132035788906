import styled, { css } from 'styled-components';
import {
  primaryDarkNew,
  primaryNew,
  infoGradientRight,
  primaryGradientRight,
  primaryGradient,
  dangerGradient,
  infoGradient,
  infoGradientLeft,
  primaryNewHover,
  white,
  black,
  darkGradientLeft,
  lightGradientLeft,
  darkerGradient,
} from 'themes/variables';
import SVG from 'react-inlinesvg';
import media from 'themes/media';
import Slider from 'react-slick';
import { Dropdown } from 'reactstrap';

import ReactModal from 'react-modal';
import { Button, Card } from 'components';
import { BannerForWebsite, BannerForWebsiteMobile } from 'containers/App/constants';

export const SubscriptionBanner = styled.div`
  background: ${(props) => props.background || infoGradientLeft};
  display: none;
  border-radius: 25px;
  padding: 5px 10px;
  margin-bottom: 30px;

  ${media.large`
  display: flex;
  `};
`;

export const WhiteSVG = styled(SVG)`
  & circle {
    fill: #fff;
  }
  &.with-red {
    & path {
      stroke: red;
    }
  }

  & path {
    stroke: #fff;
  }
`;

export const PrimarySVG = styled(SVG)`
  & path {
    stroke: rgb(${primaryNew}, 0.3);
  }
`;

export const PrimaryDarkSVG = styled(SVG)`
  & path {
    stroke: rgb(${primaryDarkNew}, ${(props) => props.opacityVal || 1});
  }
`;

export const PrimaryMainSVG = styled(SVG)`
  & path {
    stroke: rgb(${primaryNew});
  }
`;

export const LegendList = styled.div`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 650px;
  margin-left: 40px;

  li {
    display: flex;
    font-size: 14px;
    color: rgba(${primaryDarkNew});
    margin-bottom: 10px;
    margin-right: 15px;
    min-width: 200px;

    span {
      margin-right: 10px;
      position: relative;
      top: 7px;
      width: 6px;
      height: 6px;
      border-radius: 2px;
    }
  }
`;

export const CountList = styled.div`
  list-style: none;
  margin-right: 20px;

  li {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      line-height: 34px;
      margin-bottom: 5px;
      letter-spacing: 0.4px;
    }

    p {
      letter-spacing: 0.3px;
      line-height: 28px;
      color: rgba(${primaryDarkNew}, 0.5);
      margin-bottom: 0;
    }

    svg {
      margin-right: 20px;
      width: 30px;
      height: 30px;
      & g {
        fill: rgb(${primaryNew});
      }
    }
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 30px;
`;

export const Column1 = styled.div`
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: 1366px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  ${media.extraLarge`
    flex: 0 0 25%;
    max-width: 25%;
  `};
`;
export const Column2 = styled.div`
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: 1366px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  ${media.extraLarge`
    flex: 0 0 75%;
    max-width: 75%;
  `};
`;

export const TileCard = styled.div`
  padding: 10px;
  display: inline-grid;
  border-radius: 10px;
  &.card-lg {
    padding: 13px;
  }
  &.light-bg {
    background: ${lightGradientLeft};
  }
  &.danger-tile {
    background: ${dangerGradient};
  }
  &.secondary-tile {
    background: ${primaryGradientRight};
  }
  &.primary-tile {
    background: ${primaryGradient};
  }
  &.info-tile {
    background: ${infoGradient};
  }
  &.client-icon {
    background: ${infoGradientRight};
  }
  &.client-icon-bg {
    background: ${darkGradientLeft};
    .user-icon {
      margin: 10px;
    }
    .star-icon {
      width: 16px;
      height: 16px;
      margin-left: -20px;
      margin-top: 20px;
    }
  }
  &.project-icon-bg {
    background: rgb(${primaryNewHover});
  }
  &.light-grey-bg {
    background: rgba(${primaryNew}, 0.1);
  }
  &.mr-2 {
    margin-right: 16px;
  }
  svg {
    height: 20px;
    width: 20px;
  }
`;

export const EmptyCardContainer = styled.div`
  align-items: flex-start;
  @media (max-width: 576px) {
    align-items: unset !important;
  }
`;

export const CardContainer = styled.div`
  margin: auto;
  text-align: -webkit-center;
  width: 70%;
  H2 {
    text-align: center;
    color: rgb(${white});
  }
  .talent-search-input {
    margin-top: 1.5em;
    input {
      width: 100% !important;
      background: rgba(${white}) !important;
    }
  }
`;

export const SearchOptions = styled.ul`
  width: 60%;
  padding: 10px 5px;
  height: 170px;
  background: #fff;
  overflow-y: auto;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 10;
  border: 1px solid rgba(${primaryNew}, 0.1);
  margin-top: 2px;
  list-style: none;
  border-radius: 3px;
  box-shadow: 0px 5px 20px rgba(${primaryDarkNew}, 0.08);
  ${media.xsmall`
      width: 230px;
    `}
  ${media.small`
      width: 300px;
    `}
    ${media.medium`
      width: 60%;
    `}

    ${media.extraLarge`
      width: 60%;
    `}
  &.autocomplete-dropdown-container {
    height: 230px;
  }

  &.no-options {
    height: auto;
    li {
      padding: 0;
      opacity: 0.5;
      &:hover {
        background: transparent;
      }
    }
  }

  &.auto-complete-full-width {
    z-index: 1005 !important;
    width: 200.5%;
    height: auto;
  }

  li {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    padding: 10px 10px 10px 34px;
    color: rgb(${primaryDarkNew});
    background: transparent;
    border-radius: 4px;
    position: relative;

    svg {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 14px;
      height: 14px;
      z-index: 1;
      transform: translateY(-50%);
      circle,
      path {
        stroke: rgba(${primaryDarkNew}, 0.3);
      }
    }

    &:hover,
    &.option-active {
      background: rgb(${primaryNew}, 0.05);
    }
    ${media.large`
      font-size: 16px;
    `};
    &.suggestion-item {
      font-size: 16px;
    }
  }
`;
const responsiveWidth = css`
  width: 100%;

  ${media.xsmall`
    width: 230px;
  `}
  ${media.small`
    width: 300px;
  `}
  ${media.medium`
    width: 100%;
  `}
  ${media.large`
    width: 100%;
  `}
  ${media.extraLarge`
    width: 100%;
  `}
`;

export const SearchBox = styled.div`
  input {
    font-size: 14px;
    line-height: 16px;
    padding: 9px 15px;
  }
  input:focus ~ svg {
    circle,
    path {
      stroke: rgb(${primaryNew});
    }
  }
  svg.search-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    transform: translateY(-50%);
    top: 50%;
  }

  .client-header & {
    ${responsiveWidth}

    input {
      font-size: 16px;
      padding-left: 40px;
      height: 46px;
      ${media.xsmall`
         padding-right:140px
       `}
      ${media.large`
         padding-right:125px
      `}
    }

    svg.search-icon {
      left: 15px;
      circle,
      path {
        stroke: rgba(${primaryDarkNew}, 0.5);
      }
    }

    input.has-value ~ .close-btn {
      display: block;
    }
    .close-btn {
      display: none;
      position: absolute;
      border: 0;
      background: none;
      right: 20%;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      outline: none;
      ${media.xsmall`
      right: 48%;
       `}
      ${media.small`
      right: 37%;
      `}
      ${media.medium`
      right: 19%;
      `}
      ${media.extraLarge`
      right: 20%;
    `}
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`;

export const JobWidgetSlider = styled(Slider)`
  max-width: 100%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;

  .slick-list {
    padding: 0 !important;
  }
  &.slide-team {
    .slick-dots {
      bottom: 0px;
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0px;
    li {
      width: 8px !important;
      height: 8px !important;
      margin: 0 7px;

      &.slick-active {
        button {
          &:before {
            background: rgb(${white});
            opacity: 1;
          }
        }
      }
      button {
        width: 8px !important;
        height: 8px !important;

        &:before {
          opacity: 0.5;
          background: rgb(${white});
          font-size: 0;
          width: 8px !important;
          height: 8px !important;
          border-radius: 30px;
        }
      }
    }
  }
`;

export const TalentTeamDropdown = styled(Dropdown)`
  z-index: 1;
  height: 46px;
  display: flex;
  align-items: center;
  &.show {
    button.btn-secondary {
      transition: 0s all;
      color: rgb(${primaryDarkNew});
      &:hover,
      &:focus,
      &:not(:disabled):not(:disabled):active {
        box-shadow: none;
        color: rgb(${primaryDarkNew});
        background: none;
      }
      svg {
        g {
          fill: rgb(${primaryDarkNew});
        }
      }
    }
  }
  button.btn-secondary {
    border: none;
    padding: 0;
    background: none;
    font-family: 'GT-Walsheim-Pro-Regular';
    color: rgba(${primaryDarkNew});
    letter-spacing: 0.3px;
    font-size: 16px;
    padding-right: 20px;

    &.opacity-100 {
      color: rgb(${primaryDarkNew});
      &:hover,
      &:focus,
      &:not(:disabled):not(:disabled):active {
        color: rgb(${primaryDarkNew});
      }
    }

    &:hover,
    &:focus,
    &:not(:disabled):not(:disabled):active {
      box-shadow: none;
      color: rgba(${primaryDarkNew}, 0.5);
      background: none;
    }

    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: none;
    }

    svg {
      height: 16px;
      width: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .dropdown-menu {
    top: 6px !important;
    margin-top: 10px;
    border-radius: 3px;
    padding: 13px 33px;
    min-width: 150px;
    border: 0;
    box-shadow: 0 2px 14px rgba(${black}, 0.07);
    & .dropdown-item {
      font-size: 16px;
      letter-spacing: 0.25px;
      color: rgba(${primaryDarkNew}, 0.5);
      padding: 7px 0;

      &:hover,
      &:focus {
        background-color: transparent;
      }
      &:focus {
        outline: none;
      }

      &.active {
        background: none;
        color: rgb(${primaryDarkNew});
        position: relative;
      }
    }
  }
`;

export const FeaturedTalentCards = styled.div`
  overflow: hidden;
  padding: 20px;
  border: 1px solid rgba(${primaryNew}, 0.1);
  height: 188px;
  background-color: white;
  border-radius: 10px;

  &:hover {
    border: 1px solid rgb(${primaryNew}, 0.2);
    cursor: pointer;
  }

  .talent-avtar {
    max-width: 60px;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .talent-team-logo {
    border-radius: 10px;
  }

  .talent-team-name {
    font-size: 20px !important;
  }
`;

export const StyledVideoContainer = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  // height: 536px;
  height: 225px;
  position: relative;
  text-align: center;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  .video-header {
    position: relative;
    color: white;
    width: 100%;
    display: flex;
    /* text-shadow: 1px 1px 8px rgba(0,0,0,0.6); */
  }
`;

export const ProductTourHeader = styled('div')`
  overflow: hidden;
  background-image: ${({ bgImage }) => `url(${bgImage}), ${darkerGradient}`};
`;

export const ProductTourModalComponent = styled(ReactModal)`
  .modal-content {
    max-width: 600px;
    min-height: 563px;
    overflow: hidden;
    background-color: transparent !important;
  }

  ${ProductTourHeader} {
    overflow: hidden;
    height: 160px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 0 !important;
    position: relative;

    ${media.medium`
    height: 260px;
  `}
  }

  .modal-body {
    background-color: white !important;
    min-height: initial !important;
    overflow: hidden !important;
    max-height: initial !important;
    padding: 25px !important;

    ${media.medium`
    padding: 40px !important;
  `}

    .benefits-list {
      ul {
        list-style: inside;
        list-style-position: inside !important;
        list-style-type: disc !important;

        li {
          opacity: 0.7;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #04004e;
        }
      }
      .title {
        color: #12c472;
      }
    }
  }
`;

export const SkipTourButton = styled(Button)`
  position: absolute;
  top: 50px;
  right: 50px;
`;

export const HomeItemCard = styled(Card)`
  cursor: pointer;
  border: 0 !important;

  .first-column {
    height: 60px;
  }

  .iconContainer {
    border: 1px solid #d1d6f5;
    width: 60px;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(0deg, #e8ebfa, #e8ebfa), linear-gradient(0deg, #d1d6f5, #d1d6f5);
  }
`;

export const HomeActionCard = styled(Card)`
  cursor: pointer;
  border: 0 !important;
  overflow: hidden;

  &:hover {
    box-shadow: 0 2px 14px rgba(${black}, 0.07);
  }

  .numbers {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: rgb(${primaryNew});
    display: flex;
    justify-content: center;
    align-items: center;

    p {
    }
  }

  .text {
    width: 50%;
  }
  .desc {
    width: 80%;
  }

  .about-icon {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #d1d6f5, #d1d6f5);
    border: 1px solid #d1d6f5;
  }
  .mask {
    z-index: 1;
    width: 160px;
    height: 160px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 70%;
  }
  &.create-tile-container {
    margin-top: 2.5em;
  }
`;

export const CreateNewProject = styled('div')`
  .body {
    max-width: 70%;
  }
`;

export const OnboardingDropdown = styled('div')`
  margin-top: 20px;
  .menu {
    width: 100%;
    background: transparent;

    .item {
      margin-left: 30px;
      margin-top: 10px;
      .empty-radio {
        margin-right: 10px;
        width: 16px;
        height: 16px;
        opacity: 0.7px;
        border: 1px solid #04004e;
        border-radius: 100%;
      }
    }
  }

  .user-icon {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #d1d6f5;
  }

  .step-done {
    text-decoration: line-through;
    opacity: 70%;
  }
`;

export const NotchupNewBanner = styled.div`
  width: 100%;
  height: 300px;
  /* padding-bottom: 10px; */
  cursor: pointer;

  ${media.medium`
  height: 200px;
  background-image: ${() => `url(${BannerForWebsite})`};
  `}

  ${media.large`
    height: 350px;    
  `}

  ${media.extraLarge`
    height: 400px;    
  `}
  overflow: hidden;
  background-image: ${() => `url(${BannerForWebsiteMobile})`};
  background-size: 100% 100%;
`;

export const GPTSuggestionContainer = styled.div`
  ${responsiveWidth}
  display: flex;
  gap: 10px;
  ${media.xsmall`
  width: 100%;
  overflow-x: auto;
  `}
  .suggestion-item {
    background: #ffffff30;
    border-radius: 30px;
    border: 1px solid #04004e33;
    padding: 12px 20px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      border: 1px solid #ffffff;
    }
    ${media.xsmall`
      min-width: 100px;
      flex-shrink: 0;
    `}
    ${media.medium`
      flex-shrink: 1
    `}
  }
  &.bottom-suggestion {
    position: absolute;
    display: flex;
    ${media.xsmall`
      flex-direction: column;
    `}
    ${media.medium`
      flex-direction: row;
    `}
    bottom: 0;
    width: 95%;
    display: flex;
    .suggestion-item {
      &:hover {
        border: 1px solid #04004e33;
      }
    }
  }
`;
