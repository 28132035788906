import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import isString from 'lodash/isString';
import StorageService from 'utils/StorageService';
import history from 'utils/history';
import * as amplitude from '@amplitude/analytics-browser';
import { localeInfo } from 'containers/TalentListingPage/constants';
import { textItemRender } from 'containers/TalentListingPage/utils';
import ShowMoreText from 'components/ShowMore/ShowMoreText';
import parse from 'html-react-parser';
import { StyledPagination } from 'components';
import {
  TALENT_PARTNER,
  AMBASSADOR,
  TALENT,
  AGENCY,
  CLIENT,
  CLIENT_DASHBOARD,
  REFERRAL,
  TALENT_REFERRAL_UTM,
  CLIENT_REFERRAL_UTM,
  userProfileIcon,
} from './constants';
import 'rc-pagination/assets/index.css';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { getUserType, gtm, userRoleAbbreviation } from 'utils/Helper';
import { capitalizeFirstLetter } from 'containers/Auth/PersonalDetails/utils';

export const checkForZero = (temp) => {
  let output = 1;
  if (temp.length >= 1) {
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].rating === 0 || temp[i].rating === undefined) {
        output = 0;
        break;
      }
    }
  }
  return output;
};

// freelancer talent user
export const stepSize = (step) => {
  const registerType = StorageService.get('registerType');
  const totalSteps = registerType === 'agency' ? 7 : 9;
  const stepValue = Math.ceil(100 / totalSteps);
  return stepValue * step;
};

export const redirectToPage = (_, redirection, step, currentStep) => {
  if (step !== currentStep && !redirection) {
    switch (+step) {
      case 0:
        history.replace(`${TALENT}/registration-type`);
        break;
      case 1:
        history.replace(`${TALENT}/about-you`);
        break;
      case 2:
        history.replace(`${TALENT}/qualifications`);
        break;
      case 3:
        history.replace(`${TALENT}/experience`);
        break;
      case 4:
        history.replace(`${TALENT}/projects`);
        break;
      case 5:
        history.replace(`${TALENT}/skills`);
        break;
      case 6:
        history.replace(`${TALENT}/preferences`);
        break;
      case 7:
        history.replace(`${TALENT}/salary-billing`);
        break;
      case 8:
        history.replace(`${TALENT}/document-upload`);
        break;
      case 9:
        history.replace(`${TALENT}/dashboard`);
        break;
      default:
    }
  }
};

export const onBoardingPages = [
  { page: 0, url: '/about-you' },
  { page: 1, url: '/about-you' },
  { page: 2, url: '/qualifications' },
  { page: 3, url: '/experience' },
  { page: 4, url: '/projects' },
  { page: 5, url: '/skills' },
  { page: 6, url: '/preferences' },
  { page: 7, url: '/salary-billing' },
  { page: 8, url: '/document-upload' },
  { page: 9, url: '/dashboard' },
];

export const redirectPageURL = (step) => {
  let output = '';
  output = TALENT + onBoardingPages?.[step]?.url;
  return output;
};

export const agencyOnBoardingPages = [
  { page: 0, url: '/about-you' },
  { page: 1, url: '/about-company' },
  { page: 2, url: '/add-talents' },
  { page: 3, url: '/certifications' },
  { page: 4, url: '/credentials' },
  { page: 5, url: '/payout' },
  { page: 6, url: '/add-directors-shareholders' },
  { page: 7, url: '/documents' },
  { page: 8, url: '/dashboard' },
];

export const agencyRedirectPageURL = (step) => {
  let output = '';
  output = AGENCY + agencyOnBoardingPages[step].url;
  return output;
};

export const agencyRedirectToPage = (history, redirection, step, currentStep) => {
  if (step !== currentStep && !redirection) {
    switch (step) {
      case 0:
        history.replace(`${AGENCY}/about-you`);
        break;
      case 1:
        history.replace(`${AGENCY}/about-company`);
        break;
      case 2:
        history.replace(`${AGENCY}/add-talents`);
        break;
      case 3:
        history.replace(`${AGENCY}/certifications`);
        break;
      case 4:
        history.replace(`${AGENCY}/credentials`);
        break;
      case 5:
        history.replace(`${AGENCY}/payout`);
        break;
      case 6:
        history.replace(`${AGENCY}/add-directors-shareholders`);
        break;
      case 7:
        history.replace(`${AGENCY}/documents`);
        break;
      case 8:
        history.replace(`${AGENCY}/dashboard`);
        break;
      default:
    }
  }
};

// client User
export const clientStepSize = (step) => {
  const totalSteps = 1;
  const stepValue = 100 / totalSteps;
  return stepValue * step;
};
export const clientRedirectToPage = (history, redirection, step, currentStep) => {
  if (step !== currentStep && !redirection) {
    switch (step) {
      case 0:
        history.replace(`${CLIENT}/verification`);
        break;
      case 1:
        history.replace(`${CLIENT}/about-you`);
        break;
      case 2:
        history.replace(`${CLIENT}/about-company`);
        break;
      case 3:
        history.replace(`${CLIENT}/company-location`);
        break;
      case 4:
        history.replace(CLIENT_DASHBOARD);
        break;
      default:
    }
  }
};

export const clientOnBoardingPages = [
  { page: 0, url: '/about-you' },
  { page: 1, url: '/about-company' },
  { page: 2, url: '/company-location' },
  { page: 3, url: '/dashboard' },
];

export const clientRedirectPageURL = (step) => {
  let output = '';
  if (!clientOnBoardingPages?.[step]?.url) {
    return '/client/about-you';
  }
  output = CLIENT + clientOnBoardingPages?.[step]?.url;
  return output;
};

export const loginLink = '/login';
export const forgotPasswordLink = '/forgot-password';
export const verifyEmailLink = '/verifyemail';
export const signupLink = '/';
export const recruiterSignupLink = `${TALENT_PARTNER}/signup`;
export const recruiterLoginLink = `${TALENT_PARTNER}/login`;
export const ambassadorSignupLink = `${AMBASSADOR}/signup`;
export const ambassadorLoginLink = `${AMBASSADOR}/login`;

export const getBadgeClass = (status) => {
  let output = '';
  switch (status) {
    case 'Requested':
    case 'Proposed':
    case 'Invited':
    case 'Draft':
      output = 'light';
      break;
    case 'Discovery':
    case 'Settled':
    case 'Hired':
      output = 'primary';
      break;
    case 'Suspended':
    case 'Suspend':
    case 'On Hold':
    case 'Rejected':
    case 'Archived':
      output = 'danger';
      break;
    case 'In Progress':
    case 'Active':
    case 'Live':
    case 'Approved':
    case 'Verified':
      output = 'success';
      break;
    case 'Kick-off':
    case 'Unregistered':
    case 'Registered':
    case 'Submitted':
    case 'In review':
      output = 'warning';
      break;
    case 'Done':
      output = 'alert';
      break;
    case 'Skill':
      output = 'secondary';
      break;
    case 'Closed':
      // case 'Archived':
      output = 'archived';
      break;

    default:
  }
  return output;
};

export const getDynamicInterviewBadgeClass = (status) => {
  let output = '';
  switch (status) {
    case 'Proposed':
      output = 'light';
      break;
    case 'Discovery':
    case 'Settled':
    case 'Accepted':
      output = 'primary';
      break;
    case 'Suspended':
    case 'Suspend':
    case 'On Hold':
    case 'Rejected':
    case 'Canceled':
    case 'Cancelled':
      output = 'danger';
      break;
    case 'In Progress':
    case 'Active':
    case 'Approved':
    case 'Verified':
    case 'Done':
      output = 'success';
      break;
    case 'Requested':
    case 'Unregistered':
    case 'Registered':
    case 'Submitted':
    case 'In review':
    case 'Pending':
    case 'Invited':
      output = 'warning';
      break;
    case 'Closed':
      output = 'alert';
      break;
    case 'Skill':
      output = 'secondary';
      break;

    default:
  }
  return output;
};

export const statusList = [
  { label: 'All', value: -1 },
  { label: 'Ongoing', value: 1 },
  { label: 'Drafted', value: 2 },
  { label: 'Closed', value: 3 },
];

export const getDateWithTimeZone = (date) => {
  //  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const parsedDate = moment.utc(date);
  const convertedDate = parsedDate.format('MMM D, YYYY, hh:mm A z');
  return convertedDate;
};

export const getDynamicCompetitionBadgeClass = (status) => {
  let output = '';
  switch (status) {
    case 'Ongoing':
      output = 'success';
      break;
    case 'Closed':
      output = 'primary';
      break;
    case 'Drafted':
      output = 'warning';
      break;

    default:
  }
  return output;
};

export const getDynamicTextClass = (status) => {
  let output = '';
  switch (status) {
    case 'Requested':
    case 'Proposed':
    case 'Invited':
      output = 'light';
      break;
    case 'Discovery':
    case 'Settled':
      output = 'primary';
      break;
    case 'Rejected':
      output = 'danger';
      break;
    case 'Hired':
      output = 'success';
      break;
    case 'Kick-off':
    case 'Unregistered':
    case 'Registered':
    case 'Submitted':
    case 'In review':
      output = 'warning';
      break;
    case 'Closed':
    case 'Done':
      output = 'alert';
      break;
    case 'Skill':
      output = 'secondary';
      break;

    default:
  }
  return output;
};

export const recruiterOnBoardingPages = [
  { page: 0, url: '/about-you' },
  { page: 1, url: '/about-company' },
  { page: 2, url: '/document-upload' },
  { page: 3, url: '/dashboard' },
];

export const recruiterRedirectPageURL = (step) => {
  let output = '';
  output = TALENT_PARTNER + recruiterOnBoardingPages[step].url;
  return output;
};

export const recruiterRedirectToPage = (history, redirection, step, currentStep) => {
  if (step !== currentStep && !redirection) {
    switch (step) {
      case 0:
        history.replace(`${TALENT_PARTNER}/about-you`);
        break;
      case 1:
        history.replace(`${TALENT_PARTNER}/about-company`);
        break;
      case 2:
        history.replace(`${TALENT_PARTNER}/document-upload`);
        break;
      case 3:
        history.replace(`${TALENT_PARTNER}/dashboard`);
        break;
      default:
    }
  }
};

export const ambassadorOnBoardingPages = [
  { page: 0, url: '/about-you' },
  { page: 1, url: '/document-upload' },
  { page: 2, url: '/dashboard' },
];

export const ambassadorRedirectPageURL = (step) => {
  let output = '';
  output = AMBASSADOR + ambassadorOnBoardingPages[step].url;
  return output;
};

export const ambassadorRedirectToPage = (history, redirection, step, currentStep) => {
  if (step !== currentStep && !redirection) {
    switch (step) {
      case 0:
        history.replace(`${AMBASSADOR}/about-you`);
        break;
      case 1:
        history.replace(`${AMBASSADOR}/document-upload`);
        break;
      case 2:
        history.replace(`${AMBASSADOR}/dashboard`);
        break;
      default:
    }
  }
};

export const redirectTo = (_, pathname, extra = {}) => {
  history.push({
    pathname,
    extra,
  });
};

export const redirectToType = (pathname, type, extra = {}) => {
  history.push({
    pathname,
    redirection: type,
    extra,
  });
};

export const talentProfileRedirect = (_, pathname, id, type, extra = {}) => {
  history.push({
    pathname: `${pathname}${id}`,
    redirection: type,
    extra,
  });
};

export const redirectBack = (_, pathname, tab) => {
  history.push({
    pathname,
    tab,
  });
};

export const getPageData = (data, pageNum, pageSize) => {
  const pageStart = (pageNum - 1) * pageSize;
  const pageEnd = pageNum * pageSize - 1;
  const startIndex = pageStart;
  const endIndex = pageEnd > data.length - 1 ? data.length - 1 : pageEnd;

  const newData = [];
  for (let i = startIndex; i <= endIndex; i++) {
    newData.push(data[i]);
  }
  return newData;
};

export const paginationComponent = (paginationData, DEFAULT_PAGE_SIZE, onChangeFn, pageNum = '', className) => {
  let output = '';
  if (get(paginationData, 'totalDocs') > DEFAULT_PAGE_SIZE) {
    output = (
      <StyledPagination
        total={get(paginationData, 'totalDocs')}
        className={className}
        {...(pageNum ? { current: pageNum } : { current: get(paginationData, 'page') })}
        defaultPageSize={get(paginationData, 'limit')}
        onChange={onChangeFn}
        itemRender={textItemRender}
        locale={localeInfo}
      />
    );
  }
  return output;
};

export const showMoreDiv = (htmlText, plainText, lines = 5) => (
  <div className="read-more-less-content">
    <ShowMoreText lines={lines} more="more" less="" anchorClass="links" expanded={false} plainText={plainText}>
      {parse(htmlText)}
    </ShowMoreText>
  </div>
);

export const talentPages = [
  'about-you',
  'projects',
  'skills',
  'qualifications',
  'experience',
  'preferences',
  'salary-billing',
  'document-upload',
  'dashboard',
  'my-profile',
  'account-settings',
  'job-briefs',
  'my-projects',
  'my-teams',
  'team',
  'project-detail',
  'timesheets',
  'invoices',
  'knowledge-base',
  'community',
  'career-paths',
  'learning-development',
  'perks',
  'competitions',
  'wellbeing',
  'profile-view',
  'referrals',
  'validate-skills',
  'talents',
  'clients',
  'create-team',
  'add-team',
  'teams',
  'interviews',
  // 'wallet',
  // 'wallet-transactions',
];

export const agencyPages = [
  'about-you',
  'about-company',
  'add-talents',
  'certifications',
  'credentials',
  'payout',
  'add-directors-shareholders',
  'documents',
  'dashboard',
  'agency-profile',
  'agency-account-settings',
  'quotes',
  'quote-detail',
  'agency-projects',
  'agency-project-detail',
  'timesheets',
  'invoices',
  'agency-statements',
  'resources',
  'agency-planning',
  'talent-profile',
  'profile-view',
  'job-briefs',
  'talent',
];

export const clientPages = [
  'about-you',
  'about-company',
  'company-location',
  'dashboard',
  'start-project',
  'request-received',
  'talent-listing',
  'listings',
  'my-profile',
  'talent-profile',
  'account-settings',
  'projects',
  'project-detail',
  'timesheets',
  'competitions',
  'talents',
  'billing',
  'contracts',
  'payments',
  'job-briefs',
  'profile-view',
  'create-project',
  'create-competition',
  'edit-competition',
  'edit-project',
  'perks',
  'interviews',
  'create-brief',
  'checkout',
  'hire-team',
  'feedback',
  'schedule-interview',
];

export const adminPages = [
  'login',
  'projects',
  'clients',
  'agencies',
  'talents',
  'timesheets',
  'listings',
  'project-detail',
  'interviews',
  'interview-detail',
  'quotes',
  'talent-profile',
  'client-detail',
  'agency-detail',
  'job-briefs',
  'dashboard',
  'profile-view',
  'quote-detail',
  'referrals',
  'talent-partners',
  'talent-partner-detail',
  'ambassadors',
  'ambassador-detail',
  'account-settings',
  'teams',
  'schedule-interview',
  'edit-project',
  'create-project',
  'add-company',
  'company-listing',
  'invite',
];

export const recruiterPages = ['about-you', 'about-company', 'document-upload', 'dashboard', 'talents', 'job-briefs'];

export const ambassadorPages = ['about-you', 'about-company', 'document-upload', 'dashboard', 'talents', 'clients'];

export const checkIfHasAccessURL = (userType) => {
  const { pathname } = history.location;
  const filteredPathname = pathname.split('/', 3);
  let userPagesArray = '';
  if (userType === 1) {
    userPagesArray = talentPages;
  } else if (userType === 2) {
    userPagesArray = clientPages;
  } else if (userType === 3) {
    userPagesArray = agencyPages;
  } else if (userType === 4) {
    userPagesArray = adminPages;
  } else if (userType === 5) {
    userPagesArray = recruiterPages;
  } else if (userType === 6) {
    userPagesArray = ambassadorPages;
  }

  let output = '';
  if (filteredPathname.length === 2) {
    output = true;
  } else if (filteredPathname.length === 3 && !filteredPathname[2]) {
    output = true;
  } else {
    const { 2: thirdValue } = filteredPathname;
    output = userPagesArray.includes(thirdValue);
  }

  return output;
};

export const checkIfWhatAreYouLooking = (lookingFor) => {
  let output = false;

  if (
    !isEmpty(get(lookingFor, 'design')) ||
    !isEmpty(get(lookingFor, 'softwareDevelopment')) ||
    !isEmpty(get(lookingFor, 'developmentTeam')) ||
    !isEmpty(get(lookingFor, 'dataAiMl'))
  ) {
    output = true;
  }
  return output;
};

export const checkTalentSignupIsCompleted = (type) => {
  let isCompleted = false;
  const userVersion = StorageService.get('userVersion');
  const apiSignupStep = Number(StorageService.get('apiSignupStep'));
  const registerType = StorageService.get('registerType');
  // client onboarded means a client invited this talent
  const clientOnboardedTalent = StorageService.get('clientOnboarded') === 'true' && StorageService.get('availability') === 'false';

  if (type === 'talent') {
    isCompleted =
      (apiSignupStep >= 6 && userVersion !== 'v2') ||
      ((registerType === 'freelancer' ? apiSignupStep >= 6 : apiSignupStep >= 7) && userVersion === 'v2') ||
      (registerType === 'freelancer' && clientOnboardedTalent === true && apiSignupStep >= 5);
  }
  if (type === 'talent_agency') {
    isCompleted = apiSignupStep >= 5;
  }
  return isCompleted;
};

export const checkAgencySignupIsCompleted = () => {
  const signupStep = parseInt(StorageService.get('signupStep'), 10);
  const userVersion = StorageService.get('userVersion');
  const apiSignupStep = Number(StorageService.get('apiSignupStep'));
  const isCompleted =
    (signupStep && apiSignupStep >= 8 && userVersion !== 'v2') || (signupStep && apiSignupStep === 8 && userVersion === 'v2');
  return isCompleted;
};

export const checkClientSignupIsCompleted = () => {
  const currentSignupStep = Number(StorageService.get('apiSignupStep'));
  const signupStep = currentSignupStep >= 3 ? 3 : currentSignupStep;
  const userVersion = StorageService.get('userVersion');
  const isCompleted = (signupStep && signupStep >= 3 && userVersion !== 'v2') || (signupStep && signupStep === 3 && userVersion === 'v2');
  return isCompleted;
};

export const storeUserProfileId = (profileId = '', userId = '') => {
  StorageService.set('profileId', profileId, { hash: true });
  StorageService.set('userId', userId, { hash: true });
};

export const getReferralLink = (referEarnType) => {
  const id = StorageService.get('userId');
  if (id) {
    const profileId = StorageService.get('profileId');
    const currentHost = window.location.host;
    const protocolType = window.location.protocol;
    const utmParams = referEarnType === CLIENT ? CLIENT_REFERRAL_UTM : TALENT_REFERRAL_UTM;
    return `${protocolType}//${currentHost}/${referEarnType === CLIENT ? 'client' : 'talent'}/${profileId}${REFERRAL}/${id}?${utmParams}`;
  }
  return '';
};

export function getCurrentPathFromURL() {
  const { pathname } = history.location;
  const splittedPathname = pathname.split('/');
  return splittedPathname[splittedPathname.length - 1];
}

export function getCurrentProfileStep() {
  const apiSignupStep = Number(StorageService.get('apiSignupStep'));
  const updatedApiSignupStep = apiSignupStep === 0.1 ? 0 : apiSignupStep;
  // const updatedApiSignupStep = apiSignupStep === 0.1 ? 1 : apiSignupStep;
  const userVersion = StorageService.get('userVersion');
  const step = userVersion === 'v2' ? updatedApiSignupStep + 1 : updatedApiSignupStep;
  return step;
}

export const stringTrimmer = (obj) => {
  const entries = Object.entries(obj).map((ele) => {
    if (isString(ele[1])) {
      ele[1] = ele[1].trim();
    }
    return ele;
  });
  return Object.fromEntries(entries);
};

export const fullNameGenerator = (obj) => {
  let nameStr = '';
  if (has(obj, 'firstName')) {
    nameStr += `${obj.firstName} `;
  }
  if (has(obj, 'lastName')) {
    nameStr += obj.lastName;
  }
  return nameStr;
};

export const imgErrorHandler = (e) => {
  e.target.onerror = null;
  e.target.src = userProfileIcon;
};

export const updateTextWithLink = (text = '') => {
  const regex = /<p>(https?:\/\/.*?)<\/p>/g;
  let updatedText = text.replace(regex, '<a href="$1" target="_blank">$1</a>');

  const regexAnchorWithoutTarget = /<a(?![^>]*\btarget=)[^>]*href="https?:\/\/[^"]*"[^>]*>/g;
  updatedText = updatedText.replace(regexAnchorWithoutTarget, (match) => match.replace(/<a /, '<a target="_blank" '));

  return updatedText;
};

export const getTooltipContent = (fromObject, itemName) => <FormattedMessage {...fromObject[itemName]} />;

const toPascalCase = (str) =>
  str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('_');

export const getHash = (location, value) => {
  const updatedCaseValue = toPascalCase(value);
  if (location.pathname.includes('admin')) {
    return `#Admin_${updatedCaseValue}`;
  } else if (location.pathname.includes('talent')) {
    return `#Talent_${updatedCaseValue}`;
  } else if (location.pathname.includes('agency')) {
    return `#Agency_${updatedCaseValue}`;
  }
  return `#Client_${updatedCaseValue}`;
};

export const findFirstUniqueColor = (arr1, arr2) => arr1.find((color) => !arr2.includes(color));

export const teamCompositionColors = ['5,148,218', '214, 48, 255', '255, 168, 0', '54, 151, 29', '255, 77, 1'];

export const getStylesForComposition = (teamMembers, itemKey) => {
  const skillColors = {};
  Object.values(teamMembers).forEach((teamMember) => {
    if (!teamMember[itemKey]) {
      skillColors[teamMember.profileId] = ['lightgrey'];
    }
    teamMember[itemKey]?.forEach((skill) => {
      const color = teamMember?.color;
      const skillName = skill.name;
      if (skillName in skillColors) {
        skillColors[skillName].push(`rgb(${color})`); // Ensure color is prefixed with rgb
      } else {
        skillColors[skillName] = [`rgb(${color})`];
      }
    });
  });
  const finalStyles = {};
  Object.keys(skillColors).forEach((skill) => {
    if (skillColors[skill].length > 1) {
      finalStyles[skill] = {
        'background-image': `linear-gradient(to right, ${skillColors[skill].join(',')})`,
        color: 'transparent',
        'background-clip': 'text',
      };
    } else {
      finalStyles[skill] = { color: skillColors[skill][0] };
    }
  });
  return finalStyles;
};

export const getDiscProfileData = (talents) => {
  const data = [
    { name: 'D', type: 'Dominance' },
    { name: 'I', type: 'Influence' },
    { name: 'S', type: 'Steadiness' },
    { name: 'C', type: 'Conscientiousness' },
  ];
  if (talents?.length) {
    talents.forEach((member) => {
      data.forEach((discItem, index) => {
        const name = member?.name || member?.firstName;
        if (name) {
          data[index][name] = member?.discProfileScore?.[discItem.type] || 0;
        }
      });
    });
  }
  return data;
};

export const initAmplitude = () => {
  if (process.env.NODE_ENV === 'production') {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_ANALYTICS_KEY);
  }
};

export const trackAmplitudeData = (eventType, eventProperties) => {
  if (process.env.NODE_ENV === 'production') {
    const userEmail = StorageService.get('userEmail');

    // Set the userId in Amplitude
    if (userEmail) {
      amplitude.setUserId(userEmail); // Set the user ID as the email
    }
    amplitude.track(eventType, eventProperties);
  }
};

export const triggerAnalyticsEvent = (
  { userType = '', labelData = '', actionName = '', actionUser = 'talent', email = '' },
  optionalData = {},
) => {
  const actualUserType = userType || getUserType();
  const formattedActionName = capitalizeFirstLetter(actionName);
  const formattedActionUser = capitalizeFirstLetter(actionUser);
  const formattedLabel = `${actualUserType}|${actionName}_${actionUser}|${labelData}`;
  const userRoleAbbr = userRoleAbbreviation[actualUserType.toLowerCase()] || actualUserType;
  const action = `${userRoleAbbr} - ${formattedActionName} ${formattedActionUser}`;
  const actualEmail = email || StorageService.get('userEmail');
  // // GTM
  gtm({
    action: `${formattedActionName} ${formattedActionUser}`,
    label: formattedLabel,
    category: `${formattedActionName} ${formattedActionUser}`,
    directGA: true,
  });
  trackAmplitudeData(`${action}`, { email: actualEmail, userType: actualUserType, ...optionalData });
};

export const handleCloseIframeContainer = ({ closeIframe, elementId, ...extraData }) => {
  if (closeIframe && elementId) {
    window.parent.postMessage({ closeClientSignupIframe: closeIframe, elementId, ...extraData }, '*');
  }
};
