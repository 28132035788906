import React from 'react';
import PropTypes from 'prop-types';
import { userExists } from 'utils/Helper';
import get from 'lodash/get';
import { H1 } from 'components';
import Emitter from 'utils/emitter';
import Logo from 'components/Brand';
import TalentLogo from 'components/Brand/TalentLogo';
import history from 'utils/history';
import { routes } from 'containers/App/constants';
import { HamburgerToggle, PageTitleContainer } from './header-style';
import StorageService from 'utils/StorageService';

export class PageTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  setConstructor = () => {
    const stateData = {
      isSignIn: userExists(),
    };
    this.setState({ ...stateData });
  };

  componentDidMount() {
    this.setConstructor();
    Emitter.on(Emitter.EVENTS.HAMBURGER_TOGGLE, (isOpen) => {
      this.setState({ isOpen });
    });
  }

  menuToggle = () => {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        const { isOpen } = this.state;
        Emitter.emit(Emitter.EVENTS.HAMBURGER_TOGGLE, isOpen);
      },
    );
  };

  getFormattedPath = (pathName) => {
    let output = '';
    output = pathName.split('/', 3).join('/');
    return output;
  };

  getPageTitle = () => {
    const { title = '' } = this.props;
    let output = '';
    const pathName = get(history.location, 'pathname');
    const formattedPath = this.getFormattedPath(pathName);
    output = routes.find((obj) => obj.paths.find((i) => this.getFormattedPath(i.path) === formattedPath));
    return output ? output.headerTitle : title;
  };

  getNavbarLogo = () => {
    const { currentRole, windowSize } = this.props;
    if (windowSize >= 768) {
      return currentRole === 'talent' || currentRole === 'agency' ? <TalentLogo /> : <Logo />;
    }
    return null;
  };

  render() {
    const { isSignIn } = this.state;
    return (
      <PageTitleContainer className="d-flex flex-column page ms-2 me-2">
        <div className="d-flex flex-row">
          {this.getNavbarLogo()}
          {isSignIn && <HamburgerToggle onClick={this.menuToggle} />}
          <H1 className="mb-0">{this.getPageTitle()}</H1>
        </div>
      </PageTitleContainer>
    );
  }
}

PageTitle.defaultProps = {
  currentRole: 'admin',
  title: '',
  windowSize: 567,
};

PageTitle.propTypes = {
  currentRole: PropTypes.string,
  title: PropTypes.string,
  windowSize: PropTypes.number,
};

export default PageTitle;
